import { useContext } from 'react';
import uniqueId from 'lodash/uniqueId';

// muiv5
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// constants
import { Tooltip } from '@mui/material';
import { YEAR_GROUPS, ERROR_MESSAGES } from '../utils/constants';

import ResultsStore from '../../../store/ResultsPage';

// components
import { AnalyticViewLoader } from './Loaders';

import { LABEL_COLOR } from '../../SearchResults/constants';

export const AnalyticsCard = ({ data }: any) => {
  const { title, colorScheme, sponsor_name: sponsor, groupTitle, approval_year: year } = data;
  return (
    <Grid key={uniqueId()} item xs={6} sm={6} lg={2} xl={2} md={2}>
      <Stack
        spacing={1}
        sx={{
          borderRight: 1,
          borderColor: 'gray.light',
          borderTopRightRadius: 10,
          borderBottomRightRadius: 10
        }}>
        <Typography color='primary' fontWeight='bold' variant='subtitle1'>
          {year}
        </Typography>
        <Tooltip title={groupTitle}>
          <Typography
            noWrap
            variant='activeIngredient'
            fontWeight='bold'
            sx={{ textTransform: 'capitalize' }}>
            {groupTitle}
          </Typography>
        </Tooltip>
        <Tooltip title={title}>
          <Typography
            noWrap
            variant='tradeName'
            sx={{ color: LABEL_COLOR[colorScheme.toLowerCase() || 'ema'] }}>
            {title.toUpperCase()}
          </Typography>
        </Tooltip>
        <Tooltip title={sponsor}>
          <Typography color='gray.main' noWrap variant='subtitle1'>
            {Array.isArray(sponsor) ? sponsor.join('; ').toUpperCase() : sponsor.toUpperCase()}
          </Typography>
        </Tooltip>
      </Stack>
    </Grid>
  );
};

const AnalyticView = ({ applicationData }: any) => {
  const { resultsState } = useContext(ResultsStore);

  if (resultsState.loading) {
    return <AnalyticViewLoader />;
  }
  if (resultsState.error) {
    return (
      <Stack display='flex' justifyContent='center' sx={{ height: '40vh' }}>
        <Typography align='center' fontWeight='bold' variant='h3'>
          {ERROR_MESSAGES[resultsState.error]}
        </Typography>
      </Stack>
    );
  }
  return (
    <Stack sx={{ mx: '25px' }}>
      {YEAR_GROUPS?.map((item: string) => {
        if (!Object.keys(applicationData).includes(item) || !applicationData[item]?.length) {
          return null;
        }
        return (
          <Grid
            container
            key={uniqueId()}
            spacing={2}
            sx={{ mt: 2, bgcolor: 'white.main', borderRadius: 4 }}>
            {/* year range box */}
            <Grid
              item
              xs={12}
              sx={{
                p: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                bgcolor: 'primary.lightBluish'
              }}>
              <Typography color='white.main' fontWeight='bold' variant='h3'>
                {item?.split('-')[0]}
              </Typography>
              <Typography color='white.main' fontWeight='bold' variant='h3'>
                -
              </Typography>
              <Typography color='white.main' fontWeight='bold' variant='h3'>
                {item?.split('-')[1]}
              </Typography>
            </Grid>

            {/* applications within a year range */}
            <Grid p={1} item container spacing={3} xs={12}>
              {applicationData[item]
                ?.sort((a: any, b: any) => (a.approval_year > b.approval_year ? 1 : -1))
                ?.map((row: { [key: string]: any }) => {
                  return <AnalyticsCard key={uniqueId()} data={row} />;
                })}
            </Grid>
          </Grid>
        );
      })}
    </Stack>
  );
};

export default AnalyticView;
