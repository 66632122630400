import { createSvgIcon } from '@mui/material';

const AustraliaFlagIcon = createSvgIcon(
  <svg width='18' height='12' viewBox='0 0 18 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_14043_12832)'>
      <path d='M0 0H18V12H0V0Z' fill='#2A5F9E' />
      <path
        d='M8.94046 0V1.07368L7.35801 1.95439H8.94046V4.03509H6.86678L8.94046 5.18597V5.98947H8.00362L5.42467 4.55439V5.98947H3.4738V4.28421L0.407131 5.98947H-0.0419922V4.91579L1.54397 4.03509H-0.0419922V1.95439H2.03169L-0.0419922 0.8V0H0.89485L3.4738 1.43158V0H5.42467V1.70526L8.49485 0H8.94046Z'
        fill='white'
      />
      <path
        d='M5.01064 0H3.88783V2.43158H-0.0419922V3.55439H3.88783V5.98947H5.01064V3.55439H8.94046V2.43158H5.01064V0Z'
        fill='#ED4C5C'
      />
      <path d='M5.4248 4.03516L8.94059 5.98954V5.43516L6.4213 4.03516H5.4248Z' fill='#2A5F9E' />
      <path d='M5.4248 4.03516L8.94059 5.98954V5.43516L6.4213 4.03516H5.4248Z' fill='white' />
      <path
        d='M5.42467 4.03516L8.94046 5.98954V5.43516L6.42117 4.03516H5.42467ZM2.48081 4.03516L-0.0419922 5.43516V5.98954L3.4738 4.03516H2.48081Z'
        fill='#ED4C5C'
      />
      <path d='M3.4738 1.95439L-0.0419922 0V0.550877L2.48081 1.95439H3.4738Z' fill='#2A5F9E' />
      <path d='M3.4738 1.95439L-0.0419922 0V0.550877L2.48081 1.95439H3.4738Z' fill='white' />
      <path
        d='M3.4738 1.95439L-0.0419922 0V0.550877L2.48081 1.95439H3.4738ZM6.42117 1.95439L8.94046 0.550877V0L5.42467 1.95439H6.42117Z'
        fill='#ED4C5C'
      />
      <path
        d='M11.9504 4.86722L12.3847 4.3374L12.2399 5.01171L12.9155 5.0358L12.3123 5.34887L12.7225 5.87869L12.1193 5.5897L11.9504 6.26401L11.7815 5.5897L11.1784 5.87869L11.5885 5.34887L10.9854 5.0358L11.6609 5.01171L11.5161 4.3374L11.9504 4.86722Z'
        fill='white'
      />
      <path
        d='M4.456 8.81079L5.18063 7.92676L4.93909 9.0519L6.06629 9.09208L5.05986 9.61446L5.74423 10.4985L4.7378 10.0163L4.456 11.1414L4.1742 10.0163L3.16776 10.4985L3.85214 9.61446L2.8457 9.09208L3.97291 9.0519L3.73137 7.92676L4.456 8.81079Z'
        fill='white'
      />
      <path
        d='M13.5383 2.48929L13.9726 1.95947L13.8278 2.63378L14.5034 2.65787L13.9002 2.97094L14.3104 3.50076L13.7072 3.21177L13.5383 3.88608L13.3694 3.21177L12.7663 3.50076L13.1764 2.97094L12.5732 2.65787L13.2488 2.63378L13.104 1.95947L13.5383 2.48929Z'
        fill='white'
      />
      <path
        d='M15.259 4.22904L15.6933 3.69922L15.5485 4.37353L16.2241 4.39761L15.6209 4.71069L16.0311 5.2405L15.4279 4.95151L15.259 5.62582L15.0901 4.95151L14.487 5.2405L14.8971 4.71069L14.2939 4.39761L14.9695 4.37353L14.8247 3.69922L15.259 4.22904Z'
        fill='white'
      />
      <path
        d='M13.3401 10.064L13.7744 9.53418L13.6296 10.2085L14.3051 10.2326L13.702 10.5456L14.1121 11.0755L13.509 10.7865L13.3401 11.4608L13.1712 10.7865L12.568 11.0755L12.9782 10.5456L12.375 10.2326L13.0506 10.2085L12.9058 9.53418L13.3401 10.064Z'
        fill='white'
      />
      <path
        d='M14.5942 7.11205L14.5032 6.82568L14.4123 7.11205H14.1172L14.3546 7.28794L14.2636 7.57431L14.5032 7.39617L14.7428 7.57431L14.6496 7.28794L14.8892 7.11205H14.5942Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_14043_12832'>
        <rect width='18' height='12' fill='white' />
      </clipPath>
    </defs>
  </svg>,
  'AustraliaFlagIcon'
);

export default AustraliaFlagIcon;
