import titleCase from '../../../utils/titleCase';
import { CardData } from '../types';
import getApprovalTracks from './getApprovalTracks';

interface AUAPIApps {
  generic_name: Array<string>;
  application_type: string;
  approval_date: string;
  approval_days: string;
  approval_year: string;
  document_count: number;
  document_total_page_count: number;
  drug_class: string;
  formulation: Array<string>;
  inProjects: Array<any>;
  indications_and_usage: string;
  is_favorite: boolean;
  is_subscribed: boolean;
  matched_synonyms: Array<string>;
  route: Array<string>;
  s3_label_start_page: number;
  s3_label_url: string;
  source_index: string;
  sponsor_name: string;
  product_name: Array<string>;
  vin: string;
}

export const AU_IDENTIFIER_KEY = 'vin';

const mapAuApps = (apiData: AUAPIApps) => {
  const approvalTracks = getApprovalTracks(apiData, 'au');
  console.log('approvalTracks', approvalTracks);
  const cardProps: CardData = {
    source: 'au',
    title: titleCase(
      Array.isArray(apiData.product_name) ? apiData.product_name.join(' ') : apiData.product_name
    ),
    groupTitle: titleCase(
      Array.isArray(apiData.generic_name) ? apiData.generic_name.join(' ') : apiData.generic_name
    ),
    // using application_number as fallback for vin (used for reg360 header popup card)
    identifier: apiData.vin ?? (apiData as any)?.application_number,
    documentAvailableMessage: '',
    isFavorite: apiData.is_favorite,
    isSubscribed: apiData.is_subscribed,
    metadata: [
      {
        key: 'Sponsor Name',
        value: titleCase(
          (Array.isArray(apiData.sponsor_name)
            ? apiData.sponsor_name.join(';')
            : apiData.sponsor_name) || '---'
        )
      },
      {
        key: 'Formulation',
        value: titleCase(
          (Array.isArray(apiData.formulation)
            ? apiData.formulation.join(';')
            : apiData.formulation) || '---'
        )
      },
      {
        key: 'Route',
        value: titleCase(
          (Array.isArray(apiData.route) ? apiData.route.join(' ') : apiData.route) || '---'
        )
      },
      { key: 'First Approval Year', value: apiData.approval_year }
    ],
    colorScheme: apiData?.application_type,
    approvalTracks,

    ...apiData
  };

  return cardProps;
};

export default mapAuApps;
