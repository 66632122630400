/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React, { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { isEmpty, orderBy } from 'lodash';

// MUI Imports
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import Stack from '@mui/material/Stack';
import { Divider, Menu, MenuItem } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import ListItem from '@mui/material/ListItem';
import Chip from '@mui/material/Chip';

// GuideTour
import {
  ALLOWED_COMPARE_LABEL,
  ALLOWED_CSV_DOWNLOAD,
  ALLOWED_REPORT,
  ALLOWED_SORT_BY_DATE,
  SORTING_OPTIONS
} from './constants';

// Store
import RegulatoryInformationStore from '../../../store/RegulatoryInformation';
import Actions from '../../../store/RegulatoryInformation/actions';

// Custom Components
import ApprovalHistory from '../ApprovalHistory/ApprovalHistory';
import AdverseEvents from '../AdverseEvents';
import Rld from '../Rld/Rld';
import CommissionProcedures from '../CommissionProcedures/CommissionProcedures';
import ProductDetails from '../ProductsDetails';
import PostMarketingData from '../PMRC/PostmarketingData';
import ProceduralSteps from '../ProceduralSteps';
import SynonymsReg360 from '../SynonymsReg360/SynonymsReg360';
import PrecribingInfo from '../PrescribingInfo/PrescribingInfo';
import Patents from '../Patents';
import Exclusivity from '../Exclusivity';
import GenericDetails from '../Generics';
import Rems from '../Rems/Rems';
import CompareLabelButton from '../components/CompareLabelButton/CompareLabelButton';
import Summary from '../Summary/Summary';
import { UrlParameters } from '../../../types/reactRouterDom';
import ReportWidgets from '../../../components/Report/Widgets';
// import styles from './Reg360Tabs.styles';
import AuthContext from '../../../store/Auth/AuthProvider';

import { RESTRICT_DOWNLOADS_BY_ORGANIZATION } from '../../../const';
import ResourceDetails from '../ResourceDetails';
import SideEffects from '../SideEffects';

import styles from './styles';
import applicationCardStyles from '../../SearchResults/styles/ApplicationCard.styles';
import buttonStyles from '../../SearchResults/styles/Buttons.styles';
import FavoriteAndProjectActions from '../../../components/FavoritesAndProjectsAction/FavoriteAndProjectActions';
import { AddToReportIcon, DownloadIcon, SortIconVariant } from '../../../assets/svgs/Icons';
import CompanionDiagnostics from '../CompanionDiagnosticDevices/CompanionDiagnosticDevices';
import SubscriptionActions from '../../../components/Subscription/SubscriptionActions';
import OrphanDesignations from '../OrphanDesignations/OrphanDesignations';

interface TabsInfo {
  name: string;
  label: string;
  tooltip: string;
  validator?: boolean;
  render?: any;
  disabled?: boolean;
  disabledTooltipText?: string;
}

const Reg360Tabs = () => {
  // Create useStates for all the validities.
  const location = useLocation();
  const history = useHistory();
  const { module, number, source } = useParams<UrlParameters>();
  const { currentUser } = useContext(AuthContext);
  const { regulatoryState, regulatoryDispatch } = useContext(RegulatoryInformationStore);
  const [value, setValue] = React.useState('approvalHistory');
  const [showCompareLabel, setShowCompareLabel] = useState(false);
  const [sortAnchorEl, setSortAnchorEl] = useState<any>(null);
  const [selectedSort, setSelectedSort] = useState<string>('default');
  const [reportChoicesOpenAnchorElement, setReportChoiceAnchorElement] =
    useState<HTMLElement | null>(null);

  const openSort = Boolean<boolean>(sortAnchorEl);

  const handleSortClose = () => {
    setSortAnchorEl(null);
  };
  const handleOpenSort = (event: SyntheticEvent) => {
    if (regulatoryState.approvalHistory.length !== 0) {
      setSortAnchorEl(event.currentTarget);
    }
  };

  const handleSortChange = (
    e: SyntheticEvent,
    option: { id: string; sortType: string; fieldName: string; label: string }
  ) => {
    regulatoryDispatch({ type: Actions.SET_LOADING, value: true });
    setSelectedSort(option.id);
    const newRes = orderBy(
      regulatoryState.approvalHistory,
      [option.fieldName],
      [option.sortType as any]
    );
    regulatoryDispatch({ type: Actions.SET_APPROVAL_HISTORY, value: newRes });
    if (option.id === 'default') {
      regulatoryDispatch({ type: Actions.SET_SORTING_APPROVAL_HISTORY, value: false });
    } else {
      regulatoryDispatch({ type: Actions.SET_SORTING_APPROVAL_HISTORY, value: true });
    }
    regulatoryDispatch({ type: Actions.SET_LOADING, value: false });

    handleSortClose();
  };

  const handleChange = (event: React.SyntheticEvent, tabItem: any) => {
    const updatedSearchParams = new URLSearchParams(location.search);
    updatedSearchParams.set('tabname', tabItem.name);
    history.push(`${location.pathname}?${updatedSearchParams.toString()}`);
    setValue(tabItem.name);
  };

  useEffect(() => {
    const curTab = new URLSearchParams(location.search).get('tabname') || 'approvalHistory';
    if (curTab !== value) {
      setValue(curTab);
    }
  }, [location.search]);

  useEffect(() => {
    if (source === 'us') {
      const tabName = value;
      let labelsCount = 0;
      regulatoryState.approvalHistory?.forEach(element => {
        labelsCount +=
          element?.file_links?.label?.length || element?.file_links?.labels?.length || 0;
      });
      setShowCompareLabel(labelsCount > 1 && tabName === 'approvalHistory');
    }
    if (source === 'ca') {
      const tabName = value;
      let labelsCount = 0;
      regulatoryState.approvalHistory?.forEach(element => {
        // eslint-disable-next-line no-unsafe-optional-chaining
        if ('Product Monograph' in element?.file_links) {
          labelsCount += element?.file_links['Product Monograph']?.length || 0;
        }
      });
      setShowCompareLabel(labelsCount > 1 && tabName === 'approvalHistory');
    }
  }, [regulatoryState.approvalHistory, value]);

  useEffect(() => {
    if (source === 'eu') {
      const tabName = value;
      let labelsCount = 0;
      regulatoryState.commissionProcedures?.forEach(element => {
        labelsCount +=
          element?.file_links?.label?.length || element?.file_links?.labels?.length || 0;
      });
      setShowCompareLabel(labelsCount > 1 && tabName === 'commissionProcedures');
    }
  }, [regulatoryState.commissionProcedures, value]);

  const triggerOpenClicks = (event: React.SyntheticEvent, trigger: string) => {
    switch (trigger) {
      case 'addtoReport':
        regulatoryDispatch({ type: Actions.SET_TRIGGER_ADDTOREPORT, value: true });
        break;
      case 'csvDownload':
        regulatoryDispatch({ type: Actions.SET_TRIGGER_CSVDOWNLOAD, value: true });
        break;
      case 'filters':
        regulatoryDispatch({ type: Actions.SET_TRIGGER_OPENFILTERS, value: true });
        break;
      default:
        break;
    }
  };

  if (!regulatoryState?.db) {
    return <Box />;
  }
  const getSearchTypeForReporting = (
    sourceType: string,
    applicationDetails: { [key: string]: any }
  ) => {
    if (sourceType?.toLowerCase() === 'us-devices') {
      if (applicationDetails?.application_type.toLowerCase() === 'eua') {
        return 'eua_reg360';
      }
      return 'cdrh_reg360';
    }
    if (
      sourceType?.toLowerCase() === 'us' &&
      applicationDetails?.application_type.toLowerCase() === 'eua'
    ) {
      return 'eua_reg360';
    }
    return 'reg360';
  };

  const getSource = (sourceType: string, applicationDetails: { [key: string]: any }) => {
    if (sourceType?.toLowerCase() === 'us') {
      if (applicationDetails?.application_type?.toLowerCase() === 'eua') {
        return 'us-devices';
      }
    }
    return sourceType;
  };

  const getSourceForSorting = (sourceType: string, applicationDetails: { [key: string]: any }) => {
    if (sourceType?.toLowerCase() === 'us-devices') {
      if (applicationDetails?.application_type.toLowerCase() === 'eua') {
        return 'eua';
      }
    }
    return sourceType;
  };
  const getFilterPayload = () => {
    const filters = regulatoryState.appliedFilters;
    // Removing empty key form filter payload
    Object.keys(filters).forEach(key => {
      if (filters[key]?.length === 0) {
        delete filters[key];
      }
    });
    return filters;
  };

  const SOURCE_BASED_TABS: { [key: string]: Array<TabsInfo> } = {
    // Should include the 505b RLD tab.
    us: [
      {
        name: 'approvalHistory',
        label: 'Approval History',
        tooltip: 'Approval History',
        validator: true,
        render: <ApprovalHistory />
      },
      {
        name: 'pmrc',
        label: 'PMRC',
        tooltip: 'PMRC',
        validator: true,
        render: <PostMarketingData />
      },
      {
        name: 'rldInfo',
        label: 'RLD Information',
        tooltip: 'RLD Information',
        validator: regulatoryState?.applicationDetails?.submission_505b2_flag,
        render: <Rld />
      },
      {
        name: 'prescribingInfo',
        label: 'Prescribing Information',
        tooltip: 'Prescribing Information',
        validator: true,
        render: <PrecribingInfo />
      },
      {
        name: 'products',
        label: 'Products',
        tooltip: 'Products',
        validator: regulatoryState?.applicationDetails?.has_products,
        render: <ProductDetails />
      },
      {
        name: 'patents',
        label: 'Patents',
        tooltip: 'Patents',
        validator: regulatoryState?.applicationDetails?.has_patents,
        render: <Patents />
      },
      {
        name: 'exclusivity',
        label: 'Exclusivity',
        tooltip: 'Exclusivity',
        validator: regulatoryState?.applicationDetails?.has_exclusivity,
        render: <Exclusivity />
      },
      {
        name: 'generics',
        label: 'Generics',
        tooltip: 'Generics',
        validator: regulatoryState?.applicationDetails?.has_generics,
        render: <GenericDetails />
      },
      {
        name: 'synonyms',
        label: 'Synonyms',
        tooltip: 'Synonyms',
        validator: true,
        render: <SynonymsReg360 />
      },
      {
        name: 'rems',
        label: 'REMS',
        tooltip: 'REMS',
        validator: true,
        disabled:
          typeof regulatoryState?.applicationDetails?.has_rems === 'boolean'
            ? !regulatoryState?.applicationDetails?.has_rems
            : false,
        disabledTooltipText: 'Data is not available',
        render: <Rems />
      },
      {
        name: 'companionDiagnosticDevices',
        label: 'Companion Diagnostic Devices',
        tooltip: 'Companion Diagnostic Devices',
        validator: true,
        disabled:
          typeof regulatoryState?.applicationDetails?.has_companion_diagnostic_devices === 'boolean'
            ? !regulatoryState?.applicationDetails?.has_companion_diagnostic_devices
            : false,
        disabledTooltipText: 'Data is not available',
        render: <CompanionDiagnostics />
      }
    ],
    eu: [
      {
        name: 'approvalHistory',
        label: 'Approval History',
        tooltip: 'EMA EPAR Assessment History',
        validator: true,
        render: <ApprovalHistory />
      },
      {
        name: 'prescribingInfo',
        label: 'Prescribing Information',
        tooltip: 'Prescribing Information',
        validator: true,
        render: <PrecribingInfo />
      },
      {
        name: 'products',
        label: 'Products',
        tooltip: 'Products',
        validator: regulatoryState?.applicationDetails?.has_products,
        render: <ProductDetails />
      },
      {
        name: 'proceduralSteps',
        label: 'Procedural Steps',
        tooltip: 'Procedural Steps',
        validator: true,
        render: <ProceduralSteps />
      },
      {
        name: 'synonyms',
        label: 'Synonyms',
        tooltip: 'Synonyms',
        validator: true,
        render: <SynonymsReg360 />
      },
      {
        name: 'commissionProcedures',
        label: 'Commission Procedures',
        tooltip: 'Union Register of Medical Products',
        validator: true,
        render: <CommissionProcedures />
      },
      {
        name: 'orphanDesignations',
        label: 'Orphan Designations',
        tooltip: 'Orphan Designations',
        validator: true,
        disabled:
          typeof regulatoryState?.applicationDetails?.orphan_medicine === 'boolean'
            ? !regulatoryState?.applicationDetails?.orphan_medicine
            : false,
        disabledTooltipText: 'Data is not available',
        render: <OrphanDesignations />
      }
    ],
    ca: [
      {
        name: 'approvalHistory',
        label: 'Approval History',
        tooltip: 'Approval History',
        validator: true,
        render: <ApprovalHistory />
      },
      {
        name: 'prescribingInfo',
        label: 'Prescribing Information',
        tooltip: 'Prescribing Information',
        validator: true,
        render: <PrecribingInfo />
      },
      {
        name: 'products',
        label: 'Products',
        tooltip: 'Products',
        validator: regulatoryState?.applicationDetails?.has_products,
        render: <ProductDetails />
      },
      {
        name: 'patents',
        label: 'Patents',
        tooltip: 'Patents',
        validator: regulatoryState?.applicationDetails?.has_patents,
        render: <Patents />
      },
      {
        name: 'synonyms',
        label: 'Synonyms',
        tooltip: 'Synonyms',
        validator: true,
        render: <SynonymsReg360 />
      }
    ],
    jp: [
      {
        name: 'approvalHistory',
        label: 'Approval History',
        tooltip: 'Approval History',
        validator: true,
        render: <ApprovalHistory />
      },
      {
        name: 'prescribingInfo',
        label: 'Prescribing Information',
        tooltip: 'Prescribing Information',
        validator: true,
        render: <PrecribingInfo />
      },
      {
        name: 'products',
        label: 'Products',
        tooltip: 'Products',
        validator: regulatoryState?.applicationDetails?.has_products,
        render: <ProductDetails />
      },
      {
        name: 'other_documents',
        label: 'Resources',
        tooltip: 'Resources',
        validator: regulatoryState?.applicationDetails?.has_resources,
        render: <ResourceDetails />
      },
      {
        name: 'side_effects',
        label: 'Side effects',
        tooltip: 'Side effects',
        validator: regulatoryState?.applicationDetails?.has_adverse_reactions,
        render: <SideEffects />
      }
    ],
    ct: [
      {
        name: 'approvalHistory',
        label: 'Trial History',
        tooltip: 'Approval History of the Clinical Trial',
        validator: true,
        render: <ApprovalHistory />
      },
      {
        name: 'adverseEvents',
        label: 'Adverse Events',
        tooltip: 'Adverse Events of the Clinical Trial',
        validator: true,
        disabled:
          typeof regulatoryState?.applicationDetails?.has_adverse_events === 'boolean'
            ? !regulatoryState?.applicationDetails?.has_adverse_events
            : false,
        disabledTooltipText: 'Data is not available',
        render: <AdverseEvents />
      }
    ],
    'us-devices': [
      {
        name: 'approvalHistory',
        label: 'Approval History',
        tooltip: 'Approval History',
        validator: true,
        render: <ApprovalHistory />
      },
      {
        name: 'summary',
        label: 'Summary',
        tooltip: 'Summary',
        validator: true,
        disabled: regulatoryState?.applicationDetails?.application_type?.toLowerCase() === 'eua',
        disabledTooltipText: 'Data is not available',
        render: <Summary />
      },
      {
        name: 'companionDiagnosticDevices',
        label: 'Companion Diagnostic Devices',
        tooltip: 'Companion Diagnostic Devices',
        validator: true,
        disabled:
          typeof regulatoryState?.applicationDetails?.has_companion_diagnostic_devices === 'boolean'
            ? !regulatoryState?.applicationDetails?.has_companion_diagnostic_devices
            : false,
        disabledTooltipText: 'Data is not available',
        render: <CompanionDiagnostics />
      }
    ],
    hpra: [
      {
        name: 'approvalHistory',
        label: 'Approval History',
        tooltip: 'IE HPRA Assessment History',
        validator: true,
        render: <ApprovalHistory />
      },
      {
        name: 'prescribingInfo',
        label: 'Prescribing Information',
        tooltip: 'Prescribing Information',
        validator: true,
        render: <PrecribingInfo />
      },
      {
        name: 'products',
        label: 'Products',
        tooltip: 'Products',
        validator: true,
        disabled: !regulatoryState?.applicationDetails?.has_products,
        disabledTooltipText: 'Products are not available',
        render: <ProductDetails />
      },
      {
        name: 'synonyms',
        label: 'Synonyms',
        tooltip: 'Synonyms',
        validator: true,
        disabled: isEmpty(regulatoryState?.applicationDetails?.active_ingredients),
        disabledTooltipText: 'Synonyms not Available due to missing Active Ingredient',
        render: <SynonymsReg360 />
      }
    ],
    hma: [
      {
        name: 'approvalHistory',
        label: 'Approval History',
        tooltip: 'EU HMA Assessment History',
        validator: true,
        render: <ApprovalHistory />
      },
      {
        name: 'prescribingInfo',
        label: 'Prescribing Information',
        tooltip: 'Prescribing Information',
        validator: true,
        disabled: !regulatoryState?.applicationDetails?.has_label,
        disabledTooltipText: 'SPC Document is not available',
        render: <PrecribingInfo />
      },
      {
        name: 'products',
        label: 'Products',
        tooltip: 'Products',
        validator: true,
        disabled: !regulatoryState?.applicationDetails?.has_products,
        disabledTooltipText: 'Products are not available',
        render: <ProductDetails />
      },
      {
        name: 'synonyms',
        label: 'Synonyms',
        tooltip: 'Synonyms',
        validator: true,
        disabled: isEmpty(regulatoryState?.applicationDetails?.active_ingredients),
        disabledTooltipText: 'Synonyms not Available due to missing Active Ingredient',
        render: <SynonymsReg360 />
      }
    ],
    au: [
      {
        name: 'approvalHistory',
        label: 'Approval History',
        tooltip: 'Approval History',
        validator: true,
        render: <ApprovalHistory />
      },
      {
        name: 'prescribingInfo',
        label: 'Prescribing Information',
        tooltip: 'Prescribing Information',
        validator: true,
        render: <PrecribingInfo />
      },
      {
        name: 'products',
        label: 'Products',
        tooltip: 'Products',
        validator: true,
        render: <ProductDetails />
      },
      {
        name: 'synonyms',
        label: 'Synonyms',
        tooltip: 'Synonyms',
        validator: true,
        render: <SynonymsReg360 />
      }
    ]
  };

  // @ts-ignore
  return (
    <Stack spacing={2} sx={{ backgroundColor: 'gray.backgroundLightVariant3' }}>
      <Box sx={{ width: '100%', background: 'white' }}>
        <Box
          sx={{
            display: 'flex',
            pl: 1,
            pb: 1,
            borderBottom: 1,
            borderColor: 'divider',
            justifyContent: 'space-between'
          }}>
          <Stack
            display='flex'
            flexDirection='row'
            sx={{
              overflowX: 'auto',
              position: 'relative'
            }}>
            {regulatoryState?.db &&
              SOURCE_BASED_TABS[
                getSource(regulatoryState?.db, regulatoryState.applicationDetails)
              ].map((tabItem: TabsInfo) => {
                if (!tabItem.validator) return null;
                return (
                  <Tooltip
                    key={tabItem.name}
                    title={
                      tabItem.disabled
                        ? tabItem?.disabledTooltipText ?? 'Data is not available'
                        : ''
                    }>
                    <ListItem
                      key={tabItem.name}
                      sx={{
                        pl: 0,
                        width: 'fit-content',
                        pr: 1
                      }}>
                      <Chip
                        onClick={(e: any) => handleChange(e, tabItem)}
                        disabled={tabItem.disabled}
                        sx={styles.chips}
                        variant={value === tabItem.name ? 'filled' : 'outlined'}
                        label={tabItem.label}
                      />
                    </ListItem>
                  </Tooltip>
                );
              })}
          </Stack>
          <Stack flex='1 0 100px' />
          <Stack id='tools' direction='row' alignItems='center' spacing={1} flexShrink={0}>
            {ALLOWED_COMPARE_LABEL.includes(value) && showCompareLabel && (
              <>
                <CompareLabelButton
                  applicationName={
                    regulatoryState?.applicationDetails?.trade_name
                      ? regulatoryState.applicationDetails.trade_name
                      : undefined
                  }
                />
                <Divider orientation='vertical' sx={applicationCardStyles.divider} />
              </>
            )}
            {ALLOWED_REPORT.includes(value) && regulatoryState.approvalHistory.length !== 0 && (
              <>
                <Tooltip title='Create Report'>
                  <IconButton
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                      setReportChoiceAnchorElement(event.currentTarget)
                    }>
                    <AddToReportIcon sx={buttonStyles.buttonIcon} />
                  </IconButton>
                </Tooltip>
                <Popover
                  open={!!reportChoicesOpenAnchorElement}
                  anchorEl={reportChoicesOpenAnchorElement}
                  onClose={() => setReportChoiceAnchorElement(null)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  sx={{
                    '& .MuiPopover-paper': {
                      borderRadius: '16px !important',
                      backgroundColor: 'none'
                    }
                  }}>
                  <Box>
                    <ReportWidgets
                      widgets={['pie-widget', 'bar-widget', 'table-widget']}
                      title=''
                      dataSource={{
                        source,
                        moduleName: getSearchTypeForReporting(
                          source?.toLowerCase() as string,
                          regulatoryState.applicationDetails
                        ),
                        disableSelectionSource: true,
                        applicationNumber: decodeURIComponent(number as string),
                        filters: getFilterPayload()
                      }}
                      pathMetaData={{
                        module,
                        source,
                        application_number: decodeURIComponent(number as string),
                        href: window.location.href,
                        isFiltersApplied: Object.keys(getFilterPayload() || {}).length > 0
                      }}
                      setReportWidgetClose={setReportChoiceAnchorElement}
                    />
                  </Box>
                </Popover>
              </>
            )}
            {ALLOWED_SORT_BY_DATE.includes(value) && (
              <Tooltip
                title={
                  regulatoryState.approvalHistory.length !== 0
                    ? `Sort by ${source === 'ct' ? 'Submitted Date' : 'Approval Date'}`
                    : 'No data available for sorting'
                }
                sx={{ verticalAlign: 'unset' }}>
                <IconButton onClick={handleOpenSort}>
                  <Badge
                    variant='dot'
                    invisible={!regulatoryState.sortingApprovalHistory}
                    color={'primary' as any}>
                    <SortIconVariant sx={buttonStyles.buttonIcon} />
                  </Badge>
                </IconButton>
              </Tooltip>
            )}
            <Menu
              id='basic-menu'
              anchorEl={sortAnchorEl}
              open={openSort}
              onClose={handleSortClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button'
              }}
              PaperProps={{
                style: {
                  maxHeight: 48 * 4.5,
                  borderRadius: '16px'
                }
              }}>
              {SORTING_OPTIONS[
                getSourceForSorting(
                  source?.toLowerCase() as string,
                  regulatoryState.applicationDetails
                )
              ]?.map((option: any) => {
                return (
                  <MenuItem
                    key={option.id}
                    value={option.id}
                    sx={(selectedSort === option.id && ({ color: 'primary.main' } as any)) || {}}
                    onClick={e => handleSortChange(e, option)}>
                    {option.label}
                  </MenuItem>
                );
              })}
            </Menu>
            {regulatoryState?.applicationDetails?.application_number && (
              <SubscriptionActions
                invokedBy='application'
                isButton
                isSubscribed={regulatoryState.is_subscribed}
                setSubscriptionInHook={({ isSubscribed }: { isSubscribed: boolean }) => {
                  regulatoryDispatch({ type: Actions.SET_IS_SUBSCRIBED, value: isSubscribed });
                }}
                source={source ?? ''}
                id={regulatoryState.applicationDetails.application_number}
              />
            )}
            {regulatoryState?.applicationDetails?.application_number && (
              <FavoriteAndProjectActions
                invokedBy='applications'
                isButton
                isFavorite={regulatoryState.is_favorite}
                inProjects={regulatoryState.inProjects}
                setFavoriteInHook={({ isFavorite }: { isFavorite: boolean }) => {
                  regulatoryDispatch({ type: Actions.SET_IS_FAVORITE, value: isFavorite });
                }}
                setInProjectInHook={({ project }: { project: any }) => {
                  let projects = [];
                  if (project?.inProject) {
                    projects = regulatoryState?.inProjects.filter(
                      (p: any) => (p?.project_id ?? p?.id) !== (project?.project_id ?? project?.id)
                    );
                  } else {
                    projects = [
                      ...(regulatoryState?.inProjects ?? []),
                      {
                        id: project?.project_id ?? project?.id,
                        name: project?.name
                      }
                    ];
                  }
                  regulatoryDispatch({ type: Actions.SET_IN_PROJECTS, value: projects });
                }}
                source={source ?? ''}
                sourceIndex=''
                id={regulatoryState.applicationDetails.application_number}
              />
            )}
            {!RESTRICT_DOWNLOADS_BY_ORGANIZATION.includes(currentUser['custom:organization']) &&
              ALLOWED_CSV_DOWNLOAD.includes(value) && (
                <Tooltip
                  title={
                    regulatoryState.approvalHistory.length === 0
                      ? 'No data available for download'
                      : 'CSV download'
                  }>
                  <IconButton onClick={e => triggerOpenClicks(e, 'csvDownload')}>
                    <DownloadIcon sx={buttonStyles.buttonIcon} />
                  </IconButton>
                </Tooltip>
              )}
          </Stack>
        </Box>
      </Box>

      {SOURCE_BASED_TABS[getSource(regulatoryState?.db, regulatoryState.applicationDetails)].map(
        (tabItem: TabsInfo) => {
          if (tabItem.name !== value) return null;
          return (
            <Box key={tabItem.name} sx={{ width: '100%', pl: 1 }}>
              {tabItem.render}
            </Box>
          );
        }
      )}
    </Stack>
  );
};

export default Reg360Tabs;
