// eslint-disable-next-line import/prefer-default-export
export const US_FILTERS = [
  {
    id: 23,
    label: 'Label Sections',
    value: 'filter_label_sections',
    elasticSearchType: 'filter_label_sections',
    filterType: 'checkbox',
    showFilter: (data: any) =>
      data?.category === 'label_section_search' ||
      data?.advancedSearchQuery?.includes('label_section_search')
  },
  {
    id: 1,
    label: 'Active Ingredients',
    value: 'active_ingredients',
    elasticSearchType: 'active_ingredients',
    filterType: 'checkbox'
  },
  {
    id: 2,
    label: 'Formulation',
    value: 'formulation',
    elasticSearchType: 'formulation',
    filterType: 'checkbox'
  },
  {
    id: 3,
    label: 'Route',
    value: 'route',
    elasticSearchType: 'route',
    filterType: 'checkbox'
  },
  {
    id: 'marketing_status',
    label: 'Marketing Status',
    value: 'marketing_status_description',
    elasticSearchType: 'marketing_status',
    filterType: 'checkbox'
  },
  {
    id: 4,
    label: 'Sponsor Name',
    value: 'sponsor_name',
    elasticSearchType: 'sponsor_name',
    filterType: 'checkbox'
  },
  {
    id: 5,
    label: 'Application Number',
    value: 'application_number',
    elasticSearchType: 'appl_num',
    filterType: 'checkbox'
  },
  {
    id: 7,
    label: 'Application Type',
    value: 'application_type',
    elasticSearchType: 'appl_type',
    filterType: 'checkbox'
  },
  {
    id: 8,
    label: 'Approval Pathway',
    value: 'approval_pathway',
    elasticSearchType: 'approval_pathway',
    filterType: 'checkbox'
  },
  {
    id: 9,
    label: 'Office',
    value: 'office',
    elasticSearchType: 'office',
    filterType: 'checkbox'
  },
  {
    id: 24,
    label: 'Peds Use',
    value: 'pediatric_use',
    elasticSearchType: 'pediatric_use.pediatric_terms_matching',
    filterType: 'checkbox'
  },
  {
    id: 10,
    label: 'Division',
    value: 'division',
    elasticSearchType: 'division',
    filterType: 'checkbox'
  },
  {
    id: 11,
    label: 'Trade Name',
    value: 'trade_name',
    elasticSearchType: 'trade_name',
    filterType: 'checkbox'
  },
  {
    id: 12,
    label: 'Center',
    value: 'center',
    elasticSearchType: 'center',
    filterType: 'checkbox'
  },
  {
    id: 20,
    label: 'Submission Class',
    value: 'submission_class',
    elasticSearchType: 'submission_class_code_description',
    filterType: 'checkbox'
  },
  {
    id: 22,
    label: 'REMS',
    value: 'rems_active_flag',
    elasticSearchType: 'rems.rems_active_flag',
    filterType: 'checkbox'
  },
  {
    id: 13,
    label: 'Accelerated Approval',
    value: 'accelerated_approval',
    elasticSearchType: 'accelerated_approval_flag',

    filterType: 'radio'
  },
  {
    id: 14,
    label: 'Breakthrough',
    value: 'breakthrough_status',
    elasticSearchType: 'bt_status',
    filterType: 'radio'
  },
  {
    id: 15,
    label: 'Fast Track',
    value: 'fast_track',
    elasticSearchType: 'fast_track_flag',
    filterType: 'radio'
  },
  {
    id: 16,
    label: 'Orphan Status',
    value: 'orphan_status',
    elasticSearchType: 'orphan_status',
    filterType: 'radio'
  },
  {
    id: 17,
    label: 'RTOR',
    value: 'rtor',
    elasticSearchType: 'rtor_flag',

    filterType: 'radio'
  },
  {
    id: 18,
    label: 'Monoclonal Antibody',
    value: 'monoclonol_antibody',
    elasticSearchType: 'monoclonol_antibody',
    filterType: 'radio'
  },
  {
    id: 21,
    label: 'Advisory Committee',
    value: 'advisory_committee',
    elasticSearchType: 'advisory_committee.adcomm_flag',
    filterType: 'radio'
  },
  {
    id: 25,
    label: 'Biosimilar',
    value: 'biosimilar',
    elasticSearchType: 'biosimilar',
    filterType: 'radio'
  },
  {
    id: 27,
    label: 'Boxed Warning',
    value: 'application_boxed_warning_text',
    elasticSearchType: 'application_boxed_warning.application_boxed_warning_flag',
    filterType: 'radio'
  },
  {
    id: 19,
    label: 'First Approval Year',
    value: 'approval_year',
    elasticSearchType: 'first_approval_year',
    filterType: 'range'
  },
  {
    id: 26,
    label: 'Approval Time (days)',
    value: 'approval_time',
    elasticSearchType: 'approval_time',
    filterType: 'number-range'
  }
];

export const EU_FILTERS = [
  {
    id: 'label_sections',
    label: 'Label Sections',
    value: 'filter_label_sections',
    elasticSearchType: 'filter_label_sections',
    filterType: 'checkbox',
    showFilter: (data: any) =>
      data?.category === 'label_section_search' ||
      data?.advancedSearchQuery?.includes('label_section_search')
  },
  {
    id: 1,
    label: 'Active Ingredients',
    value: 'active_ingredients',
    elasticSearchType: 'active_ingredient',
    filterType: 'checkbox'
  },
  {
    id: 2,
    label: 'Formulation',
    value: 'formulation',
    elasticSearchType: 'formulation',
    filterType: 'checkbox'
  },
  {
    id: 3,
    label: 'Route',
    value: 'route',
    elasticSearchType: 'route',
    filterType: 'checkbox'
  },
  {
    id: 4,
    label: 'Sponsor Name',
    value: 'sponsor_name',
    elasticSearchType: 'sponsor_name',
    filterType: 'checkbox'
  },
  {
    id: 5,
    label: 'Product Number',
    value: 'product_number',
    elasticSearchType: 'product_number',
    filterType: 'checkbox'
  },
  {
    id: 6,
    label: 'Peds Use',
    value: 'pediatric_use',
    elasticSearchType: 'pediatric_use.pediatric_terms_matching',
    filterType: 'checkbox'
  },
  {
    id: 11,
    label: 'Trade Name',
    value: 'trade_name',
    elasticSearchType: 'trade_name',
    filterType: 'checkbox'
  },
  {
    id: 12,
    label: 'Accelerated Assessment',
    value: 'accelerated_assessment',
    elasticSearchType: 'accelerated_assessment',
    filterType: 'radio'
  },
  {
    id: 13,
    label: 'Conditional Approval',
    value: 'conditional_approval',
    elasticSearchType: 'conditional_approval',
    filterType: 'radio'
  },
  {
    id: 14,
    label: 'Exceptional Circumstances',
    value: 'exceptional_circumstances',
    elasticSearchType: 'exceptional_circumstances',
    filterType: 'radio'
  },
  {
    id: 15,
    label: 'Orphan Medicine',
    value: 'orphan_medicine',
    elasticSearchType: 'orphan_medicine',
    filterType: 'radio'
  },
  {
    id: 16,
    label: 'Monoclonal Antibody',
    value: 'monoclonal_antibody',
    elasticSearchType: 'monoclonal_antibody',
    filterType: 'radio'
  },
  {
    id: 17,
    label: 'Advanced Therapy',
    value: 'advanced_therapy',
    elasticSearchType: 'advanced_therapy',
    filterType: 'radio'
  },
  {
    id: 18,
    label: 'Biosimilar',
    value: 'biosimilar',
    elasticSearchType: 'biosimilar',
    filterType: 'radio'
  },
  {
    id: 19,
    label: 'Approval Year',
    value: 'approval_year',
    elasticSearchType: 'approval_date',
    filterType: 'range'
  }
];

export const HPRA_FILTERS = [
  {
    id: 10,
    label: 'Label Sections',
    value: 'filter_label_sections',
    elasticSearchType: 'filter_label_sections',
    filterType: 'checkbox',
    showFilter: (data: any) =>
      data?.category === 'label_section_search' ||
      data?.advancedSearchQuery?.includes('label_section_search')
  },
  {
    id: 1,
    label: 'Active Ingredients',
    value: 'active_ingredients',
    elasticSearchType: 'active_ingredient',
    filterType: 'checkbox'
  },
  {
    id: 2,
    label: 'Formulation',
    value: 'formulation',
    elasticSearchType: 'formulation',
    filterType: 'checkbox'
  },
  {
    id: 3,
    label: 'Route',
    value: 'route',
    elasticSearchType: 'route',
    filterType: 'checkbox'
  },
  {
    id: 8,
    label: 'Center',
    value: 'source_index',
    elasticSearchType: 'source_index',
    filterType: 'checkbox'
  },
  {
    id: 4,
    label: 'Sponsor Name',
    value: 'sponsor_name',
    elasticSearchType: 'sponsor_name',
    filterType: 'checkbox'
  },
  {
    id: 5,
    label: 'Product Number',
    value: 'product_number',
    elasticSearchType: 'product_number',
    filterType: 'checkbox'
  },
  {
    id: 6,
    label: 'Trade Name',
    value: 'trade_name',
    elasticSearchType: 'trade_name',
    filterType: 'checkbox'
  },
  {
    id: 7,
    label: 'Approval Year',
    value: 'approval_year',
    elasticSearchType: 'approval_date',
    filterType: 'range'
  }
];

export const HMA_FILTERS = [
  {
    id: 1,
    label: 'Active Ingredients',
    value: 'active_ingredients',
    elasticSearchType: 'active_ingredient',
    filterType: 'checkbox'
  },
  {
    id: 2,
    label: 'Formulation',
    value: 'formulation',
    elasticSearchType: 'formulation',
    filterType: 'checkbox'
  },
  {
    id: 3,
    label: 'Sponsor Name',
    value: 'sponsor_name',
    elasticSearchType: 'sponsor_name',
    filterType: 'checkbox'
  },
  {
    id: 4,
    label: 'Product Number',
    value: 'product_number',
    elasticSearchType: 'product_number',
    filterType: 'checkbox'
  },
  {
    id: 5,
    label: 'Reference State',
    value: 'rm_state',
    elasticSearchType: 'rm_state',
    filterType: 'checkbox'
  },
  {
    id: 6,
    label: 'Concerned State',
    value: 'cm_states',
    elasticSearchType: 'state_name',
    filterType: 'checkbox'
  },
  {
    id: 7,
    label: 'Trade Name',
    value: 'trade_name',
    elasticSearchType: 'trade_name',
    filterType: 'checkbox'
  },
  {
    id: 8,
    label: 'Approval Year',
    value: 'approval_year',
    elasticSearchType: 'approval_date',
    filterType: 'range'
  }
];

export const DEVICES_FILTERS = [
  {
    id: 1,
    label: 'Generic Name',
    value: 'generic_name',
    elasticSearchType: 'generic_name',
    filterType: 'checkbox'
  },
  {
    id: 2,
    label: 'Product Code',
    value: 'product_code',
    elasticSearchType: 'product_code',
    filterType: 'checkbox'
  },
  {
    id: 3,
    label: 'Device Class',
    value: 'device_class',
    elasticSearchType: 'device_class',
    filterType: 'checkbox'
  },
  {
    id: 4,
    label: 'Sponsor Name',
    value: 'sponsor_name',
    elasticSearchType: 'applicant',
    filterType: 'checkbox'
  },
  {
    id: 5,
    label: 'Application Number',
    value: 'application_number',
    elasticSearchType: 'application_number',
    filterType: 'checkbox'
  },
  {
    id: 6,
    label: 'Approval Pathway',
    value: 'approval_pathway',
    elasticSearchType: 'approval_pathway',
    filterType: 'checkbox'
  },
  {
    id: 11,
    label: 'Trade Name',
    value: 'trade_name',
    elasticSearchType: 'brand_name',
    filterType: 'checkbox'
  },
  {
    id: 14,
    label: 'Expedited Review Flag',
    value: 'expedited_review_flag',
    elasticSearchType: 'expedited_review_flag',
    filterType: 'radio'
  },
  {
    id: 15,
    label: 'Combination Product',
    value: 'combination_product',
    elasticSearchType: 'combination_product',
    filterType: 'radio'
  },
  {
    id: 16,
    label: 'Approval Year',
    value: 'approval_date',
    elasticSearchType: 'approval_date',
    filterType: 'range'
  }
];

export const CT_FILTERS = [
  {
    id: 15,
    label: 'NCT ID',
    value: 'nct_id',
    elasticSearchType: 'nct_id',
    filterType: 'checkbox'
  },
  {
    id: 19,
    label: 'Primary',
    value: 'primary_outcome',
    elasticSearchType: 'primary_outcome',
    filterType: 'text-search'
  },
  {
    id: 20,
    label: 'Secondary',
    value: 'secondary_outcome',
    elasticSearchType: 'secondary_outcome',
    filterType: 'text-search'
  },
  {
    id: 21,
    label: 'Has Results?',
    value: 'has_results',
    elasticSearchType: 'has_results',
    filterType: 'radio'
  },
  {
    id: 1,
    label: 'Status',
    value: 'overall_status',
    elasticSearchType: 'overall_status',
    filterType: 'checkbox'
  },
  {
    id: 2,
    label: 'Study Type',
    value: 'study_type',
    elasticSearchType: 'study_type',
    filterType: 'checkbox'
  },
  {
    id: 3,
    label: 'Phase',
    value: 'phase',
    elasticSearchType: 'phase',
    filterType: 'checkbox'
  },
  {
    id: 4,
    label: 'Gender',
    value: 'gender',
    elasticSearchType: 'gender',
    filterType: 'checkbox'
  },
  {
    id: 5,
    label: 'Number of Arms',
    value: 'number_of_arms',
    elasticSearchType: 'number_of_arms',
    filterType: 'checkbox'
  },
  {
    id: 6,
    label: 'Allocation',
    value: 'allocation',
    elasticSearchType: 'allocation',
    filterType: 'checkbox'
  },
  {
    id: 7,
    label: 'Masking',
    value: 'masking',
    elasticSearchType: 'masking',
    filterType: 'checkbox'
  },
  {
    id: 8,
    label: 'Expanded Access',
    value: 'has_expanded_access',
    elasticSearchType: 'has_expanded_access',
    filterType: 'checkbox'
  },
  {
    id: 9,
    label: 'Healthy Volunteers',
    value: 'healthy_volunteers',
    elasticSearchType: 'healthy_volunteers',
    filterType: 'checkbox'
  },
  {
    id: 10,
    label: 'Age Category',
    value: 'age_category',
    elasticSearchType: 'age_category',
    filterType: 'checkbox'
  },
  {
    id: 'country',
    label: 'Country',
    value: 'countries',
    elasticSearchType: 'country',
    filterType: 'checkbox'
  },
  {
    id: 16,
    label: 'Sponsors',
    value: 'sponsors',
    elasticSearchType: 'name',
    filterType: 'checkbox'
  },
  {
    id: 18,
    label: 'Agency Class',
    value: 'agency_class',
    elasticSearchType: 'agency_class',
    filterType: 'checkbox'
  },
  {
    id: 17,
    label: 'Interventions',
    value: 'interventions',
    elasticSearchType: 'interventions',
    filterType: 'checkbox'
  },
  {
    id: 11,
    label: 'Primary Completion',
    value: 'primary_completion',
    elasticSearchType: 'primary_completion_date',
    filterType: 'range'
  },
  {
    id: 12,
    label: 'Study Completion',
    value: 'study_completion',
    elasticSearchType: 'completion_date',
    filterType: 'range'
  },
  {
    id: 13,
    label: 'Study First Submitted',
    value: 'first_submitted_year',
    elasticSearchType: 'study_first_submitted_date',
    filterType: 'range'
  },
  {
    id: 14,
    label: 'Study Last Updated',
    value: 'last_updated_year',
    elasticSearchType: 'last_update_submitted_date',
    filterType: 'range'
  }
];

export const CANADA_FILTERS = [
  {
    id: 10,
    label: 'Label Sections',
    value: 'filter_label_sections',
    elasticSearchType: 'filter_label_sections',
    filterType: 'checkbox',
    showFilter: (data: any) => data?.category === 'label_section_search'
  },
  {
    id: 1,
    label: 'Active Ingredients',
    value: 'active_ingredients',
    elasticSearchType: 'active_ingredients',
    filterType: 'checkbox'
  },
  {
    id: 2,
    label: 'Formulation',
    value: 'formulation',
    elasticSearchType: 'formulation',
    filterType: 'checkbox'
  },
  {
    id: 3,
    label: 'Route',
    value: 'route',
    elasticSearchType: 'route',
    filterType: 'checkbox'
  },
  {
    id: 4,
    label: 'Sponsor Name',
    value: 'sponsor_name',
    elasticSearchType: 'sponsor_name',
    filterType: 'checkbox'
  },
  {
    id: 5,
    label: 'Application Number',
    value: 'vin',
    elasticSearchType: 'vin',
    filterType: 'checkbox'
  },
  {
    id: 6,
    label: 'Trade Name',
    value: 'trade_name',
    elasticSearchType: 'trade_name',
    filterType: 'checkbox'
  },
  {
    id: 7,
    label: 'Approval Year',
    value: 'approval_year',
    elasticSearchType: 'approval_year',
    filterType: 'range'
  },
  {
    id: 8,
    label: 'NOC with Condition',
    value: 'noc_w_condition_flag',
    elasticSearchType: 'noc_w_condition_flag',
    filterType: 'radio'
  },
  {
    id: 9,
    label: 'Priority Review',
    value: 'priority_review_flag',
    elasticSearchType: 'priority_review_flag',
    filterType: 'radio'
  },
  {
    id: 10,
    label: 'Clinical Information Docs',
    value: 'clinical_information_flag',
    elasticSearchType: 'clinical_information_flag',
    filterType: 'radio'
  }
];

export const AUSTRALIA_FILTERS = [
  {
    id: 1,
    label: 'Active Ingredients',
    value: 'active_ingredients',
    elasticSearchType: 'active_ingredients',
    filterType: 'checkbox'
  },
  {
    id: 2,
    label: 'Formulation',
    value: 'formulation',
    elasticSearchType: 'formulation',
    filterType: 'checkbox'
  },
  {
    id: 3,
    label: 'Route',
    value: 'route',
    elasticSearchType: 'route',
    filterType: 'checkbox'
  },
  {
    id: 4,
    label: 'Sponsor Name',
    value: 'sponsor_name',
    elasticSearchType: 'sponsor_name',
    filterType: 'checkbox'
  },
  {
    id: 5,
    label: 'Application Number',
    value: 'vin',
    elasticSearchType: 'vin',
    filterType: 'checkbox'
  },
  {
    id: 6,
    label: 'Trade Name',
    value: 'product_name',
    elasticSearchType: 'product_name',
    filterType: 'checkbox'
  },
  {
    id: 7,
    label: 'Approval Year',
    value: 'approval_year',
    elasticSearchType: 'approval_year',
    filterType: 'range'
  }
];

export const JP_FILTERS = [
  {
    id: 23,
    label: 'Label Sections',
    value: 'filter_label_sections',
    elasticSearchType: 'filter_label_sections',
    filterType: 'checkbox',
    showFilter: (data: any) =>
      data?.category === 'label_section_search' ||
      data?.advancedSearchQuery?.includes('label_section_search')
  },
  {
    id: 1,
    label: 'Active Ingredients',
    value: 'active_ingredients',
    elasticSearchType: 'active_ingredients',
    filterType: 'checkbox',
    isMulti: true
  },
  {
    id: 2,
    label: 'Formulation',
    value: 'formulation',
    elasticSearchType: 'formulation',
    filterType: 'checkbox',
    isMulti: true
  },
  {
    id: 3,
    label: 'Route',
    value: 'route',
    elasticSearchType: 'route',
    filterType: 'checkbox',
    isMulti: true
  },
  {
    id: 4,
    label: 'Sponsor Name',
    value: 'sponsor_name',
    elasticSearchType: 'sponsor_name',
    filterType: 'checkbox',
    isMulti: true
  },
  {
    id: 5,
    label: 'Application Number',
    value: 'yj_code',
    elasticSearchType: 'yj_code',
    filterType: 'checkbox',
    isMulti: true
  },
  {
    id: 6,
    label: 'Trade Name',
    value: 'trade_name',
    elasticSearchType: 'trade_name',
    filterType: 'checkbox',
    isMulti: true
  },
  {
    id: 7,
    label: 'Approval Year',
    value: 'sales_year',
    elasticSearchType: 'sales_year',
    filterType: 'range'
  }
];
