// eslint-disable-next-line import/prefer-default-export
export const SECTION_LABELS: { [key: string]: string } = {
  adverse_reactions: 'Adverse Reactions',
  clinical_pharmacology: 'Clinical Pharmacology',
  clinical_studies: 'Clinical Studies',
  contraindications: 'Contraindications',
  description: 'Description',
  dosage_and_administration: 'Dosage and Administration',
  dosage_forms_and_strengths: 'Dosage forms and Strengths',
  drug_abuse_and_dependence: 'Drug abuse and Dependence',
  drug_interactions: 'Drug Interactions',
  how_supplied_storage_and_handling: 'How Supplied Storage and Handling',
  indications_and_usage: 'Indications and Usage',
  non_clinical_toxicology: 'Non-clinical Toxicology',
  overdosage: 'Overdose',
  patient_counseling_information: 'Patient Counseling Information',
  references: 'References',
  use_in_specific_populations: 'Use in Specific Populations',
  warnings_and_precautions: 'Warnings and Precautions',
  how_supplied: 'How Supplied',
  precautions: 'Precautions',
  warnings: 'Warnings',
  annex_ii: 'Annex II',
  annex_iii: 'Annex III',
  date_of_first_authorisation_renewal_of_the_authorisation:
    'Date of First Authorisation/Renewal of the Authorisation',
  date_of_revision_of_the_text: 'Date of Revision of the Text',
  effects_on_ability_to_drive_and_use_machines: 'Effects on ability to drive and use machines',
  fertility_pregnancy_and_lactation: 'Fertility, pregnancy and lactation',
  interaction_with_other_medicinal_products_and_other_forms_of_in:
    'Interaction with other medicinal products and other forms of interaction',
  marketing_authorisation_holder: 'Marketing Authorisation Holder',
  marketing_authorisation_number: 'Marketing Authorisation Number(s)',
  name_of_the_medicinal_product: 'Name of the Medicinal Product',
  overdose: 'Overdose',
  pharmaceutical_form: 'Pharmaceutical Form',
  pharmaceutical_particulars: 'Pharmaceutical Particulars',
  pharmacodynamic_properties: 'Pharmacological Properties',
  pharmacokinetic_properties: 'Pharmacokinetic properties',
  posology_and_method_of_administration: 'Posology and method of administration',
  preclinical_safety_data: 'Preclinicalsafety data',
  qualitative_and_quantitative_composition: 'Qualitative and Quantitative Composition',
  special_warnings_and_precautions_for_use: 'Special warnings and precautions for use',
  therapeutic_indications: 'Therapeutic Indications',
  undesirable_effects: 'Undesirable effects',
  indications: 'Indications',
  serious_warnings_and_precautions_box: 'Serious Warnings and Precautions Box',
  dosage_forms_strengths_composition_and_packaging:
    'Dosage Forms Strengths Composition and Packaging',
  storage_stability_and_disposal: 'Storage Stability and Disposal',
  special_handling_instructions: 'Special Handling Instructions',
  pharmaceutical_information: 'Pharmaceutical Information',
  clinical_trials: 'Clinical Trials',
  microbiology: 'Microbiology',
  nonclinical_toxicology: 'Nonclinical Toxicology',
  supporting_product_monographs: 'Supporting Product Monographs',
  detailed_pharmacology: 'Detailed Pharmacology',
  patient_medication_information: 'Patient Medication Information',
  all_sections: 'All Sections',
  composition_and_product_description: 'Composition and Product Description',
  precautions_concerning_indications: 'Precautions Concerning Indications',
  precautions_concerning_dosage_and_administration:
    'Precautions Concerning Dosage and Administration',
  important_precautions: 'Important Precautions',
  precautions_concerning_patients_with_specific_backgrounds:
    'Precautions Concerning Patients With Specific Backgrounds',
  interactions: 'Interactions',
  influence_on_laboratory_tests: 'Influence on Laboratory Tests',
  precautions_concerning_use: 'Precautions Concerning Use',
  other_precautions: 'Other Precautions',
  pharmacokinetics: 'Pharmacokinetics',
  pharmacology: 'Pharmacology',
  physicomechanical_properties: 'Physicomechanical Properties',
  precautions_for_handling: 'Precautions for Handling',
  approval_conditions: 'Approval Conditions',
  packaging: 'Packaging',
  reference_request_and_contact_information: 'Reference Request and Contact Information',
  precaution_concerning_health_insurance_benefits:
    'Precaution Concerning Health Insurance Benefits',
  marketing_authorization_holder_etc: 'Marketing Authorization Holder',
  name_of_the_medicine: 'Name of the Medicine',
  special_warning_and_precautions_for_use: 'Special Warnings and Precautions for Use',
  interactions_other_medicines: 'Interactions with Other Medicines',
  fertility_pregnancy_lactation: 'Fertility, Pregnancy, and Lactation',
  effects_ability_drive_use_machines: 'Effects on Ability to Drive and Use Machines',
  adverse_effects: 'Adverse Effects',
  medicine_schedule: 'Medicine Schedule',
  sponsor: 'Sponsor',
  date_first_approval: 'Date of First Approval',
  date_revision: 'Date of Revision'
};

export const IGNORE_SECTION_BY_SOURCE: { [key: string]: string[] } = {
  us: ['Limitations of Use']
};

export const SECTION_RENAMING_BY_SOURCE: { [key: string]: { [key: string]: string } } = {
  us: {
    'Indications and Usage (Primary)': 'Indications and Usage'
  }
};
