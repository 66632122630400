// eslint-disable-next-line import/prefer-default-export
export const SECTION_LABELS: { [key: string]: string } = {
  adverse_reactions: 'Adverse Reactions',
  adverse_effects: 'Adverse Effects',
  clinical_pharmacology: 'Clinical Pharmacology',
  clinical_studies: 'Clinical Studies',
  contraindications: 'Contraindications',
  description: 'Description',
  dosage_and_administration: 'Dosage and Administration',
  dosage_forms_and_strengths: 'Dosage forms and Strengths',
  drug_abuse_and_dependence: 'Drug abuse and Dependence',
  drug_interactions: 'Drug Interactions',
  how_supplied_storage_and_handling: 'How Supplied Storage and Handling',
  indications_and_usage: 'Indications and Usage',
  non_clinical_toxicology: 'Non-clinical Toxicology',
  overdosage: 'Overdose',
  patient_counseling_information: 'Patient Counseling Information',
  references: 'References',
  use_in_specific_populations: 'Use in Specific Populations',
  warnings_and_precautions: 'Warnings and Precautions',
  how_supplied: 'How Supplied',
  precautions: 'Precautions',
  warnings: 'Warnings',
  annex_ii: 'Annex II',
  annex_iii: 'Annex III',
  date_of_first_authorisation_renewal_of_the_authorisation:
    'Date of First Authorisation/Renewal of the Authorisation',
  date_of_revision_of_the_text: 'Date of Revision of the Text',
  effects_on_ability_to_drive_and_use_machines: 'Effects on ability to drive and use machines',
  fertility_pregnancy_and_lactation: 'Fertility, pregnancy and lactation',
  interaction_with_other_medicinal_products_and_other_forms_of_in:
    'Interaction with other medicinal products and other forms of interaction',
  marketing_authorisation_holder: 'Marketing Authorisation Holder',
  marketing_authorisation_number: 'Marketing Authorisation Number(s)',
  name_of_the_medicinal_product: 'Name of the Medicinal Product',
  name_of_the_medicine: 'Name of the Medicine',
  special_warning_and_precautions_for_use: 'Special warning and precautions for use',
  interactions_other_medicines: 'Interactions with other medicines',
  effects_ability_drive_use_machines: 'Effects on ability to drive and use machines',
  overdose: 'Overdose',
  pharmaceutical_form: 'Pharmaceutical Form',
  pharmaceutical_particulars: 'Pharmaceutical Particulars',
  pharmacodynamic_properties: 'Pharmacological Properties',
  pharmacokinetic_properties: 'Pharmacokinetic properties',
  posology_and_method_of_administration: 'Posology and method of administration',
  preclinical_safety_data: 'Preclinicalsafety data',
  qualitative_and_quantitative_composition: 'Qualitative and Quantitative Composition',
  medicine_schedule: 'Medicine Schedule',
  sponsor: 'Sponsor',
  date_first_approval: 'Date of First Approval',
  date_revision: 'Date of Revision',
  special_warnings_and_precautions_for_use: 'Special warnings and precautions for use',
  therapeutic_indications: 'Therapeutic Indications',
  undesirable_effects: 'Undesirable effects',
  indications: 'Indications',
  serious_warnings_and_precautions_box: 'Serious Warnings and Precautions Box',
  dosage_forms_strengths_composition_and_packaging:
    'Dosage Forms Strengths Composition and Packaging',
  storage_stability_and_disposal: 'Storage Stability and Disposal',
  special_handling_instructions: 'Special Handling Instructions',
  pharmaceutical_information: 'Pharmaceutical Information',
  clinical_trials: 'Clinical Trials',
  microbiology: 'Microbiology',
  nonclinical_toxicology: 'Nonclinical Toxicology',
  supporting_product_monographs: 'Supporting Product Monographs',
  detailed_pharmacology: 'Detailed Pharmacology',
  patient_medication_information: 'Patient Medication Information',
  packaging: 'Packaging',
  approval_etc: 'Approval etc',
  contraindication: 'Contraindication',
  medicinal_efficacy_classification_name: 'Medicinal efficacy classification name',
  japanese_standard_product_classification_number:
    'Japanese standard product classification number',
  pharmacology: 'Pharmacology',
  side_effects: 'Side effects',
  main_literature: 'Main literature',
  pharmacokinetics: 'Pharmacokinetics',
  application_notes: 'Application notes',
  manufacturers_etc: 'Manufacturers etc',
  important_basic_notes: 'Important basic notes',
  composition_properties: 'Composition properties',
  references_request_and_inquiries: 'References request and inquiries',
  notes_regarding_patients_with_specific_backgrounds:
    'Notes regarding patients with specific backgrounds',
  physical_and_chemical_knowledge_regarding_active_ingredients:
    'Physical and chemical knowledge regarding active ingredients',
  clinical_results: 'Clinical results',
  handling_precautions: 'Handling precautions',
  medicinal_pharmacology: 'Medicinal pharmacology',
  interaction: 'Interaction',
  other_notes: 'Other notes',
  approval_conditions: 'Approval conditions',
  contraindications_for_concomitant_use: 'Contraindications for concomitant use',
  usage_dosage: 'Usage dosage',
  warning: 'Warning',
  efficacy_effect: 'Efficacy effect',
  concomitant_use_contraindications: 'Concomitant use contraindications',
  careful_administration: 'Careful administration',
  administration_to_children_etc: 'Administration to children etc',
  side_reactions: 'Side reactions',
  contraindications_for_combination: 'Contraindications for combination',
  properties_dosage_form: 'Properties dosage form',
  pharmacological_effects_supporting_efficacy_or_effectiveness:
    'Pharmacological effects supporting efficacy or effectiveness',
  dosage_and_method_of_adminstration: 'Dosage and Method of Administration',
  fertility_pregnancy_lactation: 'Fertility, Pregnancy, and Lactation'
};

export const menuList = [
  {
    label: 'US FDA Drugs & Biologics',
    value: 'US',
    sourceName: 'US',
    sourceDescription: 'US FDA Drugs & Biologics',
    id: 'us'
  },
  {
    label: 'EU EMA Drugs & Biologics',
    value: 'EU',
    sourceDescription: 'EU EMA Drugs & Biologics',
    sourceName: 'EU',
    id: 'eu'
  },
  {
    label: 'CA HPFB Drugs & Biologics',
    value: 'CA',
    sourceDescription: 'CA HPFB Drugs & Biologics',
    sourceName: 'CA',
    id: 'ca'
  },
  {
    label: 'IE HPRA - Drugs & Biologics',
    value: 'HPRA',
    sourceDescription: 'IE HPRA - Drugs & Biologics',
    sourceName: 'HPRA',
    id: 'hpra'
  },
  {
    label: 'JP PMDA Drugs & Biologics',
    value: 'JP',
    sourceDescription: 'JP PMDA Drugs & Biologics',
    sourceName: 'JP',
    id: 'jp'
  }
];

export const categories = [
  {
    label: 'Indications',
    value: 'indications_and_usage'
  },
  {
    label: 'Active Ingredient',
    value: 'generic_name'
  },
  {
    label: 'ATC Classification',
    value: 'atc_classification'
  },
  {
    label: 'Trade Name',
    value: 'product_name'
  },
  {
    label: 'Formulation',
    value: 'formulation'
  },
  {
    label: 'Route',
    value: 'route'
  },
  {
    label: 'Sponsor',
    value: 'sponsor'
  },
  {
    label: 'All Label Sections',
    value: 'label_section_search'
  }
];
