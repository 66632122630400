/* eslint-disable camelcase */
/* eslint-disable no-restricted-globals */
/* eslint-disable radix */
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Stack from '@mui/material/Stack';

import isEmpty from 'lodash/isEmpty';
import { Button, Tooltip } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import RegulatoryInformationStore from '../../../store/RegulatoryInformation';
import Actions from '../../../store/RegulatoryInformation/actions';
import EmptyPage from '../../../components/EmptyPage/EmptyPage';
import PDFViewer from '../../../components/PDFViewer';
import GlobalStore from '../../../store';
import GlobalActions from '../../../store/actions';

import regulatoryInformationAPI from '../../../api/pages/Regulatory360';

import styles from './PrescribingInfo.styles';
import LanguageToggleButton from '../../../components/LanguageToggleComponents/LanguageToggleButton';
import LanguageToggleText from '../../../components/LanguageToggleComponents/LanguageToggleText';
import { ALLOWED_SOURCES_PRESCRIBING_INFO, METADATA_MAPPING } from '../constant';
import { triggerChatRiaForReg360 } from '../../SearchResults/utils/trggerGlobalChatRia';

const PrecribingInfo = () => {
  const { regulatoryState, regulatoryDispatch } = useContext(RegulatoryInformationStore);
  const { dispatch } = useContext(GlobalStore) as any;
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [selectedLabel, setSelectedLabel] = useState<any>();
  const [labelData, setLabelData] = useState<Array<any>>([]);
  const [fileLink, setFileLink] = useState<string>('');
  const [basefileLink, setBasefileLink] = useState<string>();
  const [noContent, setNoContent] = useState<boolean>(false);
  const [tab, setTab] = useState<number>(0);
  const [originalTextEnabled, setOriginalTextEnabled] = useState<boolean>(false);
  const [currentFileOriginalLanguage, setCurrentFileOriginalLanguage] = useState<string>();
  const handleChange = useCallback((event: any, newValue: number) => {
    setTab(newValue);
  }, []);

  useEffect(() => {
    const setData = (data: any) => {
      const { s3_path, appl_num, submission_num, submission_type, ...filteredObject } = data[tab];
      let sortable;
      if (regulatoryState.db === 'us') {
        sortable = {
          indications_and_usage: parseInt(filteredObject.indications_and_usage),
          dosage_and_administration: parseInt(filteredObject.dosage_and_administration),
          dosage_forms_and_strengths: parseInt(filteredObject.dosage_forms_and_strengths),
          contraindications: parseInt(filteredObject.contraindications),
          warnings_and_precautions: parseInt(filteredObject.warnings_and_precautions),
          adverse_reactions: parseInt(filteredObject.adverse_reactions),
          drug_interactions: parseInt(filteredObject.drug_interactions),
          use_in_specific_populations: parseInt(filteredObject.use_in_specific_populations),
          drug_abuse_and_dependence: parseInt(filteredObject.drug_abuse_and_dependence),
          overdosage: parseInt(filteredObject.overdosage),
          description: parseInt(filteredObject.description),
          clinical_pharmacology: parseInt(filteredObject.clinical_pharmacology),
          non_clinical_toxicology: parseInt(filteredObject.non_clinical_toxicology),
          clinical_studies: parseInt(filteredObject.clinical_studies),
          references: parseInt(filteredObject.references),
          how_supplied_storage_and_handling: parseInt(
            filteredObject.how_supplied_storage_and_handling
          ),
          patient_counseling_information: parseInt(filteredObject.patient_counseling_information)
        };
      }
      // uncomment once hma has sections
      // else if (regulatoryState.db === 'hma') {
      //   setPDFMetadata(data[tab]);
      // }
      else if (regulatoryState.db === 'eu') {
        sortable = {
          name_of_the_medicinal_product: parseInt(filteredObject.name_of_the_medicinal_product),
          qualitative_and_quantitative_composition: parseInt(
            filteredObject.qualitative_and_quantitative_composition
          ),
          pharmaceutical_form: parseInt(filteredObject.pharmaceutical_form),
          indications_and_usage: parseInt(filteredObject.indications_and_usage),
          posology_and_method_of_administration: parseInt(
            filteredObject.posology_and_method_of_administration
          ),
          contraindications: parseInt(filteredObject.contraindications),
          special_warnings_and_precautions_for_use: parseInt(
            filteredObject.special_warnings_and_precautions_for_use
          ),
          interactions_with_other_medicinal_products_and_other_forms_of_interaction: parseInt(
            filteredObject.interactions_with_other_medicinal_products_and_other_forms_of_in
          ),
          fertility_pregnancy_and_lactation: parseInt(
            filteredObject.fertility_pregnancy_and_lactation
          ),
          effects_on_ability_to_drive_and_use_machines: parseInt(
            filteredObject.effects_on_ability_to_drive_and_use_machines
          ),
          undesirable_effects: parseInt(filteredObject.undesirable_effects),
          overdose: parseInt(filteredObject.overdose),
          pharmacodynamic_properties: parseInt(filteredObject.pharmacodynamic_properties),
          pharmacokinetic_properties: parseInt(filteredObject.pharmacokinetic_properties),
          preclinical_safety_data: parseInt(filteredObject.preclinical_safety_data),
          pharmaceutical_particulars: parseInt(filteredObject.pharmaceutical_particulars),
          marketing_authorisation_holder: parseInt(filteredObject.marketing_authorisation_holder),
          marketing_authorisation_number: parseInt(filteredObject.marketing_authorisation_number),
          date_of_first_authorisation_renewal_of_the_authorisation: parseInt(
            filteredObject.date_of_first_authorisation_renewal_of_the_authorisation
          ),
          date_of_revision_of_the_text: parseInt(filteredObject.date_of_revision_of_the_text)
        };
      } else if (regulatoryState.db === 'hpra') {
        sortable = {
          name_of_the_medicinal_product: parseInt(filteredObject.name_of_the_medicinal_product),
          qualitative_and_quantitative_composition: parseInt(
            filteredObject.qualitative_and_quantitative_composition
          ),
          pharmaceutical_form: parseInt(filteredObject.pharmaceutical_form),
          indications_and_usage: parseInt(filteredObject.indications_and_usage),
          posology_and_method_of_administration: parseInt(
            filteredObject.posology_and_method_of_administration
          ),
          contraindications: parseInt(filteredObject.contraindications),
          special_warnings_and_precautions_for_use: parseInt(
            filteredObject.special_warnings_and_precautions_for_use
          ),
          interactions_with_other_medicinal_products_and_other_forms_of_interaction: parseInt(
            filteredObject.interactions_with_other_medicinal_products_and_other_forms_of_interaction
          ),
          fertility_pregnancy_and_lactation: parseInt(
            filteredObject.fertility_pregnancy_and_lactation
          ),
          effects_on_ability_to_drive_and_use_machines: parseInt(
            filteredObject.effects_on_ability_to_drive_and_use_machines
          ),
          undesirable_effects: parseInt(filteredObject.undesirable_effects),
          overdose: parseInt(filteredObject.overdose),
          pharmacodynamic_properties: parseInt(filteredObject.pharmacodynamic_properties),
          pharmacokinetic_properties: parseInt(filteredObject.pharmacokinetic_properties),
          preclinical_safety_data: parseInt(filteredObject.preclinical_safety_data),
          pharmaceutical_particulars: parseInt(filteredObject.pharmaceutical_particulars),
          marketing_authorisation_holder: parseInt(filteredObject.marketing_authorisation_holder),
          marketing_authorisation_number: parseInt(filteredObject.marketing_authorisation_number),
          date_of_first_authorisation_renewal_of_the_authorisation: parseInt(
            filteredObject.date_of_first_authorisation_renewal_of_the_authorisation
          ),
          date_of_revision_of_the_text: parseInt(filteredObject.date_of_revision_of_the_text)
        };
      } else if (regulatoryState.db === 'jp') {
        sortable = {};
        data[tab]?.sections?.forEach((section: any) => {
          sortable[section.name] = parseInt(section.page_number);
        });
        // sort the object by page number
        sortable = Object.fromEntries(
          Object.entries(sortable).sort(([, a]: any, [, b]: any) => a - b)
        ) as any;
        setCurrentFileOriginalLanguage(data[tab]?.original_link || '');
      } else if (regulatoryState.db === 'ca') {
        sortable = {
          adverse_reactions: parseInt(filteredObject.adverse_reactions),
          clinical_pharmacology: parseInt(filteredObject.clinical_pharmacology),
          clinical_trials: parseInt(filteredObject.clinical_trials),
          contraindications: parseInt(filteredObject.contraindications),
          detailed_pharmacology: parseInt(filteredObject.detailed_pharmacology),
          dosage_and_administration: parseInt(filteredObject.dosage_and_administration),

          dosage_forms_strengths_composition_and_packaging: parseInt(
            filteredObject.dosage_forms_strengths_composition_and_packaging
          ),
          drug_interactions: parseInt(filteredObject.drug_interactions),
          indications: parseInt(filteredObject.indications),
          effects_on_ability_to_drive_and_use_machines: parseInt(
            filteredObject.effects_on_ability_to_drive_and_use_machines
          ),
          microbiology: parseInt(filteredObject.microbiology),
          nonclinical_toxicology: parseInt(filteredObject.nonclinical_toxicology),
          overdosage: parseInt(filteredObject.overdosage),
          patient_medication_information: parseInt(filteredObject.patient_medication_information),
          pharmaceutical_information: parseInt(filteredObject.pharmaceutical_information),
          references: parseInt(filteredObject.references),
          serious_warnings_and_precautions_box: parseInt(
            filteredObject.serious_warnings_and_precautions_box
          ),
          special_handling_instructions: parseInt(filteredObject.special_handling_instructions),
          storage_stability_and_disposal: parseInt(filteredObject.storage_stability_and_disposal),
          supporting_product_monographs: parseInt(filteredObject.supporting_product_monographs),
          warnings_and_precautions: parseInt(filteredObject.warnings_and_precautions)
        };
      } else if (regulatoryState.db === 'au') {
        sortable = {};
        data[tab]?.sections?.forEach((section: any) => {
          sortable[section.name] = parseInt(section.page_number);
        });
        // sort the object by page number
        sortable = Object.fromEntries(
          Object.entries(sortable).sort(([, a]: any, [, b]: any) => a - b)
        ) as any;
      }
      setBasefileLink(s3_path);
      setFileLink(s3_path);
      setSelectedLabel(sortable);
      setLabelData(data);
    };
    if (
      isEmpty(regulatoryState?.prescribingInfo) &&
      regulatoryState?.applicationDetails?.application_number &&
      regulatoryState?.db
    ) {
      const getData = async () => {
        const data: any = await regulatoryInformationAPI(
          regulatoryState.db,
          regulatoryState?.applicationDetails?.application_number,
          'prescribing_info'
        );
        if (!data?.data?.body) {
          setNoContent(true);
        } else {
          regulatoryDispatch({ type: Actions.SET_PRESCRIBING_INFO, value: data.data.body });
          setData(data.data.body);
        }
      };
      getData();
    } else {
      setData(regulatoryState?.prescribingInfo);
    }
  }, [regulatoryState.db, regulatoryState.applicationDetails, tab]);

  const metadata = useMemo(() => {
    return (
      METADATA_MAPPING[regulatoryState.db]?.map((data: any) => {
        return {
          ...data,
          value:
            data.valueGetter?.(regulatoryState?.prescribingInfo[tab] || {}) ||
            regulatoryState?.prescribingInfo[tab]?.[data.key]
        };
      }) || []
    );
  }, [regulatoryState?.prescribingInfo, regulatoryState.db, tab]);

  const handlePageChange = (pgnum: number) => {
    if (isNaN(pgnum)) {
      setPageNumber(1);
    } else {
      setFileLink(`${basefileLink}#page=${pgnum}`);
      setPageNumber(pgnum);
    }
  };

  const handleChatRia = () => {
    const tradeName = regulatoryState?.applicationDetails?.trade_name || '';
    let applicationNumber = regulatoryState?.applicationDetails?.application_number;
    if (regulatoryState.db?.toLowerCase() === 'eu') {
      if (regulatoryState?.applicationDetails?.center?.toLowerCase() === 'ema-who')
        applicationNumber = `EMEA-H-W-${applicationNumber}`;
      else applicationNumber = `${applicationNumber}`;
    }
    triggerChatRiaForReg360(
      applicationNumber,
      tradeName,
      GlobalActions,
      regulatoryState.db,
      dispatch
    );
  };

  if (noContent) {
    return <EmptyPage message='No content for Prescribing Info' />;
  }

  if (!selectedLabel && !['hma'].includes(regulatoryState?.db)) {
    return <EmptyPage message='Loading Prescribing Info' />;
  }

  return (
    <Box>
      {metadata && (
        <Box display='flex' alignItems='center'>
          <Box
            display='flex'
            alignItems='center'
            sx={{
              backgroundColor: 'gray.background',
              borderRadius: '6px',
              height: '26px',
              padding: '0 8px'
            }}>
            {metadata.map((data: any, index: number) => (
              <Box key={data.label} display='flex' alignItems='center'>
                <Typography fontSize={12} pr='3px' fontWeight={500}>
                  {data.label} :{' '}
                </Typography>
                <Tooltip title={`${data.label} ${data.value}`} key={data.label}>
                  <Typography fontWeight={700} fontSize={12}>
                    {data.value}
                  </Typography>
                </Tooltip>
                {index < metadata.length - 1 && (
                  <Box
                    sx={{ backgroundColor: 'green.jungle' }}
                    margin='0 8px'
                    width={4}
                    height={4}
                    borderRadius='50%'
                  />
                )}
              </Box>
            ))}
          </Box>
          {ALLOWED_SOURCES_PRESCRIBING_INFO.includes(regulatoryState.db) && (
            <>
              <Divider
                orientation='vertical'
                variant='middle'
                flexItem
                sx={{ p: '5px', height: originalTextEnabled ? 40 : 16 }}
              />{' '}
              <Tooltip title='Use Chat RIA to interact with the document'>
                <Button
                  variant='text'
                  disabled={originalTextEnabled ? !currentFileOriginalLanguage : !fileLink}
                  onClick={() => handleChatRia()}
                  sx={styles.chatRiaButton}>
                  Chat RIA
                  <SendIcon sx={styles.sendIcon} />
                </Button>
              </Tooltip>
            </>
          )}
        </Box>
      )}
      <Box display='flex' flexDirection='row'>
        {labelData?.length > 1 && (
          <Box>
            <Tabs
              value={tab}
              onChange={handleChange}
              variant='scrollable'
              scrollButtons={false}
              orientation='vertical'
              centered
              aria-label='scrollable prevent tabs example'
              sx={styles.tabs}>
              {labelData.map((row: any, index: number) => {
                return (
                  <Tab
                    key={row.title}
                    label={row.title.replaceAll('-', ' ')}
                    sx={index === tab ? styles.activeTab : styles.inactiveTab}
                  />
                );
              })}
            </Tabs>
          </Box>
        )}
        <Grid container sx={{ paddingTop: '20px' }} display='flex' flexDirection='row'>
          {ALLOWED_SOURCES_PRESCRIBING_INFO.includes(regulatoryState.db) ? (
            <Grid
              item
              xs={3}
              sx={{ justifyContent: 'center', height: 'calc(100vh - 260px)', overflow: 'auto' }}>
              <Box sx={styles.content}>
                <Typography variant='h4'>Content</Typography>
              </Box>
              <List sx={styles.background}>
                {Object.keys(selectedLabel)?.map(ele => {
                  return (
                    <Box key={ele}>
                      {!isNaN(selectedLabel[ele]) && (
                        <>
                          <Tooltip
                            title={
                              originalTextEnabled
                                ? 'Cannot change sections when original PDF is open'
                                : ''
                            }>
                            <ListItem
                              key={ele}
                              disabled={originalTextEnabled}
                              onClick={() => handlePageChange(selectedLabel[ele])}
                              sx={styles.ListItem}>
                              <ListItemText sx={styles.borderClass}>
                                <Typography variant='subtitle1' sx={styles.ListItemText}>
                                  {ele.replace(/_/g, ' ')}
                                </Typography>
                              </ListItemText>
                            </ListItem>
                          </Tooltip>
                          <Divider />
                        </>
                      )}
                    </Box>
                  );
                })}
              </List>
            </Grid>
          ) : null}
          <Grid item xs={8} sx={{ height: 'calc(100vh - 260px)' }}>
            {!isEmpty(currentFileOriginalLanguage) && (
              <Stack direction='row' justifyContent='flex-end' alignItems='center'>
                {originalTextEnabled && <LanguageToggleText styles={{ textAlign: 'start' }} />}
                <LanguageToggleButton
                  source={regulatoryState.db}
                  originalTextEnabled={originalTextEnabled}
                  toggleTextLanugage={() => {
                    setOriginalTextEnabled(!originalTextEnabled);
                  }}
                  color='green'
                />
              </Stack>
            )}
            <PDFViewer
              // @ts-ignore
              fileUrl={originalTextEnabled ? currentFileOriginalLanguage : fileLink}
              initialPage={pageNumber - 1 || 0}
              defaultScale={1.35}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default React.memo(PrecribingInfo);
