import React, { useEffect, useMemo, useState } from 'react';

import Skeleton from '@mui/lab/Skeleton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import { Avatar, Chip, IconButton, Tooltip } from '@mui/material';
import * as Select from '../CustomComponents/Selects';
import { SECTION_LABELS } from './const';

import comparisonClasses from './styles/LabelSelection.style';

import SortIcon from '../../assets/svgs/CardsPage/SortIcon';
import emaLogo from '../../Images/ema-logo.png';
import euLogo from '../../Images/eu-logo.png';

// assets

const { VivproSelect }: any = Select; // Work around for usage error.

interface LabelListProps {
  selectedApplication: any;
  isLoading: boolean;
  labelList: Array<any> | undefined;
  selectedLabel: any;
  errorMessage: string | undefined;
  // eslint-disable-next-line no-unused-vars
  onLabelSelect: (label: any, section: string) => void;
}

const LabelList: React.FC<LabelListProps> = ({
  selectedApplication,
  isLoading,
  labelList,
  selectedLabel,
  errorMessage,
  onLabelSelect
}) => {
  const [oldestTONewest, setOldestTONewest] = useState(false);
  const [cartonFilter, setCartonFilter] = useState({
    filterApplied: false,
    count: 0
  });

  useEffect(() => {
    if (labelList) {
      const sortedLabel = JSON.parse(JSON.stringify(labelList));
      setCartonFilter({
        ...cartonFilter,
        count: sortedLabel.reduce((count: number, obj: any) => {
          return count + (obj.label_type === 'carton' ? 1 : 0);
        }, 0)
      });
    }
  }, [labelList]);

  const handleSortBy = () => {
    setOldestTONewest((prev: boolean) => {
      return !prev;
    });
  };

  const handleCartonFilterBy = () => {
    setCartonFilter({
      ...cartonFilter,
      filterApplied: !cartonFilter.filterApplied
    });
  };

  const getComparisonText = () => {
    if (selectedApplication?.source?.toLowerCase() === 'us') {
      return selectedApplication?.application_type;
    }
    if (selectedApplication?.source?.toLowerCase() === 'ca') {
      return 'CA ';
    }
    if (selectedApplication?.source?.toLowerCase() === 'hpra') {
      return 'HPRA ';
    }
    if (selectedApplication?.source?.toLowerCase() === 'jp') {
      return 'JP ';
    }
    if (selectedApplication?.source?.toLowerCase() === 'hma') {
      return 'HMA ';
    }
    if (selectedApplication?.source?.toLowerCase() === 'au') {
      return 'AU   ';
    }
    return 'EMEA-H-C-';
  };

  const lableListToShow = useMemo(() => {
    if (!labelList) {
      return labelList;
    }
    const sortedList = JSON.parse(JSON.stringify(labelList));
    sortedList.sort((a: any, b: any) => {
      return oldestTONewest
        ? new Date(a.approval_date).getTime() - new Date(b.approval_date).getTime()
        : new Date(b.approval_date).getTime() - new Date(a.approval_date).getTime();
    });
    if (cartonFilter.filterApplied) {
      return sortedList.filter(
        (obj: any) => Object.hasOwnProperty.call(obj, 'label_type') && obj.label_type !== 'carton'
      );
    }

    return sortedList;
  }, [oldestTONewest, labelList, cartonFilter]);
  return (
    <>
      <Typography sx={comparisonClasses.selectLabel} align='center'>
        Select a label section for Comparison - {getComparisonText()}
        {selectedApplication?.application_number || selectedApplication?.product_number}
      </Typography>
      <Box sx={comparisonClasses.compareBox}>
        {isLoading ? (
          <Box p={1}>
            <Skeleton variant='text' width='30%' />
            <Skeleton variant='rectangular' width='90%' height={150} />
            <Skeleton variant='text' width='30%' />
            <Skeleton variant='rectangular' width='90%' height={150} />
            <Skeleton variant='text' width='30%' />
            <Skeleton variant='rectangular' width='90%' height={150} />
          </Box>
        ) : (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {errorMessage ? (
              <Box sx={comparisonClasses.labelLoadErrorMessage}>{errorMessage}</Box>
            ) : (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                {lableListToShow && (
                  <Box sx={{ display: 'flex', p: '5px', justifyContent: 'flex-end' }}>
                    <Box width='80%' pl='20%'>
                      {selectedApplication?.application_name && (
                        <Box sx={comparisonClasses.tradeNameText}>
                          {selectedApplication.application_name?.indexOf('-') !== -1
                            ? selectedApplication.application_name.substring(
                                selectedApplication.application_name.indexOf('-') + 1
                              )
                            : selectedApplication.application_name}
                        </Box>
                      )}
                    </Box>
                    <Box width='20%' display='flex' flexDirection='row' justifyContent='flex-end'>
                      {selectedApplication?.source.toLowerCase() === 'us' &&
                        cartonFilter.count !== 0 && (
                          <Tooltip
                            title={
                              cartonFilter.filterApplied
                                ? 'Show all carton labels in the list'
                                : 'Hide all carton labels in the list'
                            }>
                            <Box
                              display='flex'
                              alignItems='center'
                              onClick={handleCartonFilterBy}
                              sx={{ cursor: 'pointer' }}>
                              <Typography
                                sx={{
                                  fontSize: 12,
                                  color: 'gray.700',
                                  textDecoration: 'underline'
                                }}>
                                {cartonFilter.filterApplied
                                  ? `Show Cartons (${cartonFilter.count})`
                                  : `Hide Cartons (${cartonFilter.count})`}
                              </Typography>
                            </Box>
                          </Tooltip>
                        )}
                      <Tooltip
                        title={`Sort by ${
                          oldestTONewest ? 'Newest to Oldest' : 'Oldest to Newest'
                        }`}>
                        <IconButton onClick={handleSortBy}>
                          <Badge variant='dot' invisible={!oldestTONewest} color={'primary' as any}>
                            <SortIcon />
                          </Badge>
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                )}
                {lableListToShow &&
                  lableListToShow.map((item: any) => (
                    // eslint-disable-next-line react/jsx-no-useless-fragment
                    <React.Fragment
                      key={`${item.submission_type}-${item.approval_date}-${item.submission_num}`}>
                      {item.label.sections && item.label.sections.length > 0 && (
                        <Box
                          sx={
                            selectedLabel?.submissionType === item.submission_type &&
                            selectedLabel?.submissionNum === item.submission_num &&
                            selectedLabel?.description === item.description &&
                            selectedLabel?.approvalDate === item.approval_date
                              ? comparisonClasses.labelContainer__selected
                              : comparisonClasses.labelContainer
                          }>
                          <Box
                            pt={1}
                            onClick={() => {
                              onLabelSelect(item, '');
                            }}>
                            <Box display='flex' alignItems='center' pl={1}>
                              {selectedApplication?.source.toLowerCase() === 'eu' && (
                                <Tooltip title={`Source: ${item.source}`}>
                                  <Chip
                                    avatar={
                                      <Avatar
                                        alt='EMA'
                                        src={item.source === 'EMA' ? emaLogo : euLogo}
                                      />
                                    }
                                    label={item.source}
                                    variant='filled'
                                    sx={{
                                      backgroundColor: 'blue.main',
                                      color: 'white.main',
                                      m: 1,
                                      textTransform: 'none !important'
                                    }}
                                  />
                                </Tooltip>
                              )}
                              <Typography sx={comparisonClasses.labelDate}>
                                {item.approval_date}&nbsp;&nbsp;
                              </Typography>
                              <Typography sx={comparisonClasses.labelSup}>
                                {`${item.submission_type}${
                                  item.submission_num ? `-${item.submission_num}` : ''
                                }`}
                                &nbsp;
                              </Typography>
                              <Typography sx={comparisonClasses.labelSup}>
                                {item.description}
                              </Typography>
                              {selectedApplication?.source.toLowerCase() === 'us' &&
                                item?.label_type &&
                                item?.label_type === 'carton' && (
                                  <Box sx={{ textDecoration: 'none' }}>
                                    <Chip
                                      size='small'
                                      label='CARTON'
                                      sx={{
                                        ml: 1,
                                        height: 13,
                                        fontSize: 10,
                                        borderRadius: 1,
                                        fontWeight: 800,
                                        '& span': {
                                          px: 0.5
                                        },
                                        color: 'red.main'
                                      }}
                                    />
                                  </Box>
                                )}
                            </Box>
                            <Box display='flex' flexDirection='column'>
                              {selectedApplication?.source.toLowerCase() === 'eu' && (
                                <Box display='flex' flexDirection='column'>
                                  <Box sx={comparisonClasses.compareSubText}>
                                    <b>Variation Number: </b>&nbsp; {item.variation_number}
                                  </Box>
                                </Box>
                              )}
                              <Box
                                sx={comparisonClasses.compareText}
                                justifyContent='space-between'
                                display='flex'>
                                {!item.change?.[0] || item.change?.[0] === 'UNKNOWN' ? (
                                  <Box display='flex'>
                                    <b>Action details:</b>&nbsp;Unknown
                                  </Box>
                                ) : (
                                  item.change
                                )}
                              </Box>
                            </Box>
                          </Box>
                          <Box display='inline-flex' pl='20px' pb='10px' pt='10px'>
                            {selectedLabel?.submissionType === item.submission_type &&
                              selectedLabel?.submissionNum === item.submission_num &&
                              selectedLabel?.description === item.description &&
                              selectedLabel?.approvalDate === item.approval_date && (
                                <Box width='16.2vw'>
                                  <VivproSelect
                                    label='Select a section'
                                    value={selectedLabel?.section}
                                    options={(item.label.sections || []).map((section: any) => ({
                                      value: section.section,
                                      label: SECTION_LABELS[section.section]
                                    }))}
                                    handleChange={(event: any) => {
                                      onLabelSelect(item, event.target.value);
                                    }}
                                    icon={undefined}
                                  />
                                </Box>
                              )}
                          </Box>
                        </Box>
                      )}
                    </React.Fragment>
                  ))}
              </>
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default React.memo(LabelList);
