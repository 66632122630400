import { GridColDef } from '@mui/x-data-grid-pro';
// other components
import renderCellExpand from '../../../components/Datagrid/DatagridPopover';
import DatagridTooltip from '../../../components/Datagrid/DatagridTooltip';
import DatagridAvatars from '../../../components/Datagrid/DatagridAvatar';
import DatagridApprovalTracks from '../../../components/Datagrid/DatagridApprovalTracks';
import DatagridArrayAvatars from '../../../components/Datagrid/DatagridArrayAvatars';
import DatagridChipsPopup from '../../../components/Datagrid/DatagridChipsPopup';
import DatagridLink from '../../../components/Datagrid/DatagridLink';
import DatagridProductNameReg360 from '../../../components/Datagrid/DatagridProductNameReg360';
import DatagridIdetifier from '../../../components/Datagrid/DatagridIdetifier';
import DatagridSourceFlag from '../../../components/Datagrid/DatagridSourceFlag';
import DatagridColorNumber from '../../../components/Datagrid/DatagridColorText';
import ATCDataGridPopUpChip, {
  valueMapper
} from '../../../components/Datagrid/ATCDataGridPopUpChip';
import DatagridListView from '../../../components/Datagrid/DatagridListView';
import { getFormatDataForCSV } from './getFormatDataForCSV';
import DatagridApplicationType from '../../../components/Datagrid/DatagridApplicationType';
import DatagridActionsMenu from '../../../components/Datagrid/DatagridActionsMenu';
import DatagridColorIcon from '../../../components/Datagrid/DatagridColorIcon';
import titleCase from '../../../utils/titleCase';
import DatagridListViewWithAvatars from '../../../components/Datagrid/DatagridListViewWithAvatars';
import DatagridNestedListView from '../../../components/Datagrid/DatagridNestedListView';
import { isTruthyValue } from './helpers';
import DatagridArrayListView from '../../../components/Datagrid/DatagridArrayListView';
import VisualizeButton from '../../SearchResults/components/IndicationApprovalTimeline/VisaulizeButton';
import getATCFilterOperator from './getATCFilterOperator';

const prefixText = 'Original Approval';

export const JP_COLUMNS: GridColDef[] = [
  {
    field: 'identifier',
    headerName: 'Application Number',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    renderCell: DatagridIdetifier
  },
  {
    field: 'source',
    headerName: 'Source',
    headerClassName: 'table-header',
    width: 100,
    cellClassName: 'table-row',
    renderCell: DatagridSourceFlag
  },
  {
    field: 'product_name',
    headerName: 'Product Name',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    renderCell: DatagridProductNameReg360
  },
  {
    field: 'generic_name',
    headerName: 'Generic Name',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    renderCell: DatagridTooltip
  },
  {
    field: 'sponsor_name',
    headerName: 'Sponsor Name',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    renderCell: DatagridTooltip
  },
  {
    field: 'approval_year',
    headerName: 'First Approval Year ',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row'
  },
  {
    field: 'formulation',
    headerName: 'Formulation',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row'
  },
  {
    field: 'route',
    headerName: 'Route',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row'
  },
  {
    field: 'indications_and_usage',
    headerName: 'Indications and Usage',
    headerClassName: 'table-header',
    width: 240,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.indications_and_usage}`,
    renderCell: renderCellExpand
  }
];

export const JP_ORIGINAL_LANG_COLUMNS: GridColDef[] = [
  {
    field: 'identifier',
    headerName: 'Application Number',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    renderCell: DatagridIdetifier
  },
  {
    field: 'source',
    headerName: 'Source',
    headerClassName: 'table-header',
    width: 100,
    cellClassName: 'table-row',
    renderCell: DatagridSourceFlag
  },
  {
    field: 'product_name_jp',
    headerName: 'Product Name',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.product_name_jp}`,
    renderCell: DatagridProductNameReg360
  },
  {
    field: 'generic_name_jp',
    headerName: 'Generic Name',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    renderCell: DatagridTooltip
  },
  {
    field: 'sponsor_name_jp',
    headerName: 'Sponsor Name',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    renderCell: DatagridTooltip
  },
  {
    field: 'approval_year',
    headerName: 'First Approval Year ',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row'
  },
  {
    field: 'formulation',
    headerName: 'Formulation',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row'
  },
  {
    field: 'route',
    headerName: 'Route',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row'
  },
  {
    field: 'indications_and_usage_jp',
    headerName: 'Indications and Usage',
    headerClassName: 'table-header',
    width: 240,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.indications_and_usage_jp}`,
    renderCell: renderCellExpand
  }
];

export const US_COLUMNS: GridColDef[] = [
  {
    field: 'identifier',
    headerName: 'Application Number',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    renderCell: DatagridIdetifier
  },
  {
    field: 'source',
    headerName: 'Source',
    headerClassName: 'table-header',
    width: 100,
    cellClassName: 'table-row',
    renderCell: DatagridSourceFlag
  },
  {
    field: 'labels',
    headerName: 'Type',
    headerClassName: 'table-header',
    width: 100,
    cellClassName: 'table-row',
    renderCell: DatagridApplicationType
  },
  {
    field: 'product_name',
    headerName: 'Product Name',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => titleCase(`${row.product_name}`),
    renderCell: DatagridProductNameReg360
  },
  {
    field: 'generic_name',
    headerName: 'Generic Name',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.generic_name}`,
    renderCell: DatagridTooltip
  },
  {
    field: 'sponsor_name',
    headerClassName: 'table-header',
    headerName: 'Sponsor Name',
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.sponsor_name}`,
    renderCell: DatagridTooltip
  },
  {
    field: 'matched_submissions',
    headerName: 'Indications First Appeared',
    headerClassName: 'table-header',
    width: 280,
    cellClassName: 'table-row',
    disableExport: true,
    sortable: false,
    renderHeader: params => VisualizeButton(params),
    renderCell: params => DatagridArrayListView(params, 'matched_submissions', true)
  },
  {
    field: 'approval_year',
    headerClassName: 'table-header',
    headerName: 'First Approval Year ',
    width: 170,
    cellClassName: 'table-row'
  },
  {
    field: 'approval_pathway',
    headerClassName: 'table-header',
    headerName: 'Approval Pathway',
    width: 150,
    cellClassName: 'table-row',
    renderCell: DatagridTooltip
  },
  {
    field: 'approval_time',
    headerClassName: 'table-header',
    headerName: 'Approval Time (days)',
    width: 180,
    cellClassName: 'table-row',
    sortComparator: (v1: any, v2: any) => {
      return parseInt(v1 || 0, 10) - parseInt(v2 || 0, 10);
    }
  },
  {
    field: 'indications_and_usage',
    headerClassName: 'table-header',
    headerName: 'Indications ',
    width: 240,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.indications_and_usage}`,
    renderCell: renderCellExpand
  },
  {
    field: 'pediatric_use',
    headerClassName: 'table-header',
    headerName: 'Peds Use',
    width: 150,
    cellClassName: 'table-row',
    renderCell: DatagridChipsPopup,
    valueGetter: ({ row }) =>
      `${row.pediatric_use && row.pediatric_use.length ? row.pediatric_use.join('; ') : ''}`
  },
  {
    field: 'formulation',
    headerClassName: 'table-header',
    headerName: 'Formulation',
    width: 130,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.formulation}`,
    renderCell: DatagridTooltip
  },
  {
    field: 'route',
    headerClassName: 'table-header',
    headerName: 'Route',
    width: 100,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.route}`,
    renderCell: DatagridTooltip
  },
  {
    field: 'atc_classification',
    headerClassName: 'table-header',
    headerName: 'ATC Class',
    width: 180,
    cellClassName: 'table-row',
    renderCell: ATCDataGridPopUpChip,
    disableExport: true,
    filterOperators: getATCFilterOperator()
  },
  {
    field: 'atc_classification_export',
    headerClassName: 'table-header',
    headerName: 'ATC Class',
    filterable: false,
    width: 0,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => valueMapper(row.atc_classification, true)
  },
  {
    field: 'center',
    headerClassName: 'table-header',
    headerName: 'Center',
    width: 120,
    cellClassName: 'table-row'
  },
  {
    field: 'approvalTracks',
    headerClassName: 'table-header',
    headerName: 'Approval Tracks',
    width: 280,
    cellClassName: 'table-row',
    renderCell: DatagridApprovalTracks,
    disableExport: true
  },
  {
    field: 'accelerated_approval',
    headerClassName: 'table-header',
    headerName: `Accelerated Approval (${prefixText})`,
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.accelerated_approval_text || 'No'} `
  },
  {
    field: 'breakthrough_status',
    headerClassName: 'table-header',
    headerName: `Breakthrough (${prefixText})`,
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.breakthrough_status_text || 'No'} `
  },
  {
    field: 'fast_track',
    headerClassName: 'table-header',
    headerName: `Fast Track (${prefixText})`,
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.fast_track_text || 'No'} `
  },
  {
    field: 'orphan_status',
    headerClassName: 'table-header',
    headerName: `Orphan Status (${prefixText})`,
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.orphan_status_text || 'No'} `
  },
  {
    field: 'advisory_committee',
    headerClassName: 'table-header',
    headerName: `Advisory Committee (${prefixText})`,
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.advisory_committee_date || 'No'} `
  },
  {
    field: 'biosimilar',
    headerClassName: 'table-header',
    headerName: `Biosimilar (${prefixText})`,
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.biosimilar || 'No'} `
  },
  {
    field: 'application_boxed_warning_text',
    headerClassName: 'table-header',
    headerName: `Boxed Warning (${prefixText})`,
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.application_boxed_warning_text || 'No'} `
  },
  {
    field: 'rems_text',
    headerClassName: 'table-header',
    headerName: `REMS(${prefixText})`,
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.rems_text || 'No'} `
  },
  {
    field: 'review_status',
    headerClassName: 'table-header',
    headerName: `Review Priority`,
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.review_status || ''} `
  },
  {
    field: 'combination_product_flag',
    headerClassName: 'table-header',
    headerName: `Combination Product`,
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.combination_product_flag ? 'Yes' : 'No'} `
  },
  {
    field: 'matched_submissions_csv',
    headerName: 'Indications First Appeared',
    headerClassName: 'table-header',
    width: 280,
    cellClassName: 'table-row',
    valueGetter: ({ row }) =>
      `${
        row.matched_submissions && row.matched_submissions.length !== 0
          ? getFormatDataForCSV(row.matched_submissions, [
              'indications_and_usage_text',
              'submission',
              's3_url',
              's3_bucket',
              's3_path'
            ])
          : ''
      }`
  }
];

export const EU_COLUMNS: GridColDef[] = [
  {
    field: 'identifier',
    headerName: 'Product Number',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    renderCell: DatagridIdetifier
  },
  {
    field: 'source',
    headerName: 'Source',
    headerClassName: 'table-header',
    width: 100,
    cellClassName: 'table-row',
    renderCell: DatagridSourceFlag
  },
  {
    field: 'labels',
    headerName: 'Application Type',
    headerClassName: 'table-header',
    width: 140,
    cellClassName: 'table-row',
    renderCell: DatagridApplicationType
  },
  {
    field: 'product_name',
    headerName: 'Product Name',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => titleCase(`${row.product_name}`),
    renderCell: DatagridProductNameReg360
  },
  {
    field: 'generic_name',
    headerName: 'Generic Name',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    renderCell: DatagridTooltip
  },
  {
    field: 'sponsor_name',
    headerClassName: 'table-header',
    headerName: 'Sponsor Name',
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.sponsor_name}`,
    renderCell: DatagridTooltip
  },
  {
    field: 'matched_submissions',
    headerName: 'Indications First Appeared',
    headerClassName: 'table-header',
    width: 280,
    cellClassName: 'table-row',
    disableExport: true,
    sortable: false,
    renderHeader: params => VisualizeButton(params),
    renderCell: params => DatagridArrayListView(params, 'matched_submissions', true)
  },
  {
    field: 'approval_year',
    headerClassName: 'table-header',
    headerName: 'First Approval Year ',
    width: 180,
    cellClassName: 'table-row'
  },
  {
    field: 'indications_and_usage',
    headerClassName: 'table-header',
    headerName: 'Indications ',
    width: 240,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.indications_and_usage}`,
    renderCell: renderCellExpand
  },
  {
    field: 'pediatric_use',
    headerClassName: 'table-header',
    headerName: 'Peds Use',
    width: 100,
    cellClassName: 'table-row',
    renderCell: DatagridChipsPopup,
    valueGetter: ({ row }) =>
      `${row.pediatric_use && row.pediatric_use.length ? row.pediatric_use.join('; ') : ''}`
  },
  {
    field: 'formulation',
    headerClassName: 'table-header',
    headerName: 'Formulation',
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.formulation}`,
    renderCell: DatagridTooltip
  },
  {
    field: 'route',
    headerClassName: 'table-header',
    headerName: 'Route',
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.route}`,
    renderCell: DatagridTooltip
  },
  {
    field: 'atc_classification',
    headerClassName: 'table-header',
    headerName: 'ATC Class',
    width: 180,
    cellClassName: 'table-row',
    renderCell: ATCDataGridPopUpChip,
    disableExport: true,
    filterOperators: getATCFilterOperator()
  },
  {
    field: 'atc_classification_export',
    headerClassName: 'table-header',
    filterable: false,
    headerName: 'ATC Class',
    width: 0,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => valueMapper(row.atc_classification, true)
  },
  {
    field: 'approvalTracks',
    headerClassName: 'table-header',
    headerName: 'Approval Tracks',
    width: 280,
    cellClassName: 'table-row',
    renderCell: DatagridApprovalTracks,
    disableExport: true
  },
  {
    field: 'accelerated_assessment',
    headerClassName: 'table-header',
    headerName: `Accelerated Assessment (${prefixText})`,
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.accelerated_assessment_text || 'No'} `
  },
  {
    field: 'conditional_approval',
    headerClassName: 'table-header',
    headerName: `Conditional Approval (${prefixText})`,
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.orphan_medicine_text || 'No'} `
  },
  {
    field: 'orphan_medicine',
    headerClassName: 'table-header',
    headerName: `Orphan Medicine (${prefixText})`,
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.orphan_medicine_text || 'No'} `
  },
  {
    field: 'exceptional_circumstances',
    headerClassName: 'table-header',
    headerName: `Exceptional Circumstances (${prefixText})`,
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.exceptional_circumstances_text || 'No'} `
  },
  {
    field: 'advanced_therapy',
    headerClassName: 'table-header',
    headerName: `Advanced Therapy (${prefixText})`,
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.advanced_therapy_text || 'No'} `
  },
  {
    field: 'biosimilar',
    headerClassName: 'table-header',
    headerName: `Biosimilar (${prefixText})`,
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.biosimilar || 'No'} `
  },
  {
    field: 'combination_product_flag',
    headerClassName: 'table-header',
    headerName: `Combination Product`,
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.combination_product_flag ? 'Yes' : 'No'} `
  },
  {
    field: 'matched_submissions_csv',
    headerName: 'Indications First Appeared',
    headerClassName: 'table-header',
    width: 280,
    cellClassName: 'table-row',
    valueGetter: ({ row }) =>
      `${
        row.matched_submissions && row.matched_submissions.length !== 0
          ? getFormatDataForCSV(row.matched_submissions, [
              'indications_and_usage_text',
              'submission',
              's3_url',
              's3_bucket',
              's3_path'
            ])
          : ''
      }`
  }
];

export const DEVICES_COLUMNS: GridColDef[] = [
  {
    field: 'identifier',
    headerName: 'Application Number',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    renderCell: DatagridIdetifier
  },
  {
    field: 'source',
    headerName: 'Source',
    headerClassName: 'table-header',
    width: 100,
    cellClassName: 'table-row',
    renderCell: DatagridSourceFlag
  },
  {
    field: 'labels',
    headerName: 'Application Type',
    headerClassName: 'table-header',
    width: 140,
    cellClassName: 'table-row',
    renderCell: DatagridApplicationType
  },
  {
    field: 'product_name',
    headerClassName: 'table-header',
    headerName: 'Product Name',
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => titleCase(`${row.product_name}`),
    renderCell: DatagridProductNameReg360
  },
  {
    field: 'generic_name',
    headerName: 'Generic Name',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.generic_name}`,
    renderCell: DatagridTooltip
  },
  {
    field: 'sponsor_name',
    headerClassName: 'table-header',
    headerName: 'Sponsor Name',
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.sponsor_name}`,
    renderCell: DatagridTooltip
  },
  {
    field: 'approval_year',
    headerClassName: 'table-header',
    headerName: 'First Approval Year ',
    width: 180,
    cellClassName: 'table-row'
  },
  {
    field: 'indications_and_usage',
    headerClassName: 'table-header',
    headerName: 'Indications ',
    width: 240,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.indications_and_usage}`,
    renderCell: renderCellExpand
  },
  {
    field: 'product_code',
    headerClassName: 'table-header',
    headerName: 'Product Code',
    width: 180,
    cellClassName: 'table-row'
  },
  {
    field: 'approval_pathway',
    headerClassName: 'table-header',
    headerName: 'Approval Pathway',
    width: 180,
    cellClassName: 'table-row',
    renderCell: DatagridTooltip
  },
  {
    field: 'device_class',
    headerClassName: 'table-header',
    headerName: 'Device Class',
    width: 180,
    cellClassName: 'table-row'
  },
  {
    field: 'approvalTracks',
    headerClassName: 'table-header',
    headerName: 'Approval Tracks',
    width: 180,
    cellClassName: 'table-row',
    renderCell: DatagridApprovalTracks,
    disableExport: true
  },
  {
    field: 'combination_product',
    headerClassName: 'table-header',
    headerName: `Combination Product (${prefixText})`,
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.combination_product_text || 'No'} `
  },
  {
    field: 'expedited_review_flag',
    headerClassName: 'table-header',
    headerName: `Expedited Review Flag (${prefixText})`,
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.orphanexpedited_review_flag_text_status_text || 'No'} `
  }
];

export const CT_COLUMNS: GridColDef[] = [
  {
    field: 'next',
    headerName: '',
    headerClassName: 'table-header',
    width: 50,
    cellClassName: 'table-row',
    disableExport: true,
    valueGetter: ({ row }) => `${row.nct_id}_`,
    renderCell: DatagridActionsMenu,
    // @ts-ignore
    source: 'ct',
    // @ts-ignore
    module: 'core'
  },
  {
    field: 'nct_id_icon',
    headerName: '',
    headerClassName: 'table-header',
    disableExport: true,
    width: 50,
    cellClassName: 'table-row',
    renderCell: DatagridColorIcon,
    // @ts-ignore
    source: 'ct'
  },
  {
    field: 'nct_id',
    headerName: 'NCT ID',
    headerClassName: 'table-header',
    width: 140,
    cellClassName: 'table-row',
    renderCell: DatagridTooltip,
    // @ts-ignore
    source: 'ct'
  },
  {
    field: 'ct_gov_link',
    headerName: 'Source Link',
    headerClassName: 'table-header',
    // @ts-ignore
    isLink: true,
    width: 140,
    cellClassName: 'table-row',
    filterable: false,
    valueGetter: ({ row }) => `https://clinicaltrials.gov/study/${row.nct_id}`,
    // @ts-ignore
    source: 'ct'
  },
  {
    field: 'brief_title',
    headerName: 'Study Title',
    headerClassName: 'table-header',
    width: 280,
    cellClassName: 'table-row',
    renderCell: renderCellExpand,
    valueGetter: ({ row }) => `${row.brief_title}`
  },
  {
    field: 'study_type',
    headerName: 'Study Type',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    renderCell: DatagridTooltip
  },
  {
    field: 'phase',
    headerName: 'Phase',
    headerClassName: 'table-header',
    width: 100,
    cellClassName: 'table-row',
    renderCell: DatagridTooltip
  },
  {
    field: 'age_category',
    headerName: 'Age Group',
    headerClassName: 'table-header',
    width: 220,
    cellClassName: 'table-row',
    renderCell: DatagridArrayAvatars,
    valueGetter: ({ row }) =>
      `${row.age_category && row.age_category.length ? row.age_category.join(', ') : '---'}`,
    // @ts-ignore
    source: 'usnlm'
  },
  {
    field: 'enrollment_info',
    headerName: 'Number Enrolled',
    headerClassName: 'table-header',
    width: 150,
    cellClassName: 'table-row',
    renderCell: DatagridTooltip
  },
  {
    field: 'primary_outcome',
    headerName: 'Primary Outcome',
    headerClassName: 'table-header',
    width: 220,
    cellClassName: 'table-row',
    disableExport: true,
    renderCell: DatagridListView
  },
  {
    field: 'secondary_outcome',
    headerName: 'Secondary Outcome',
    headerClassName: 'table-header',
    width: 220,
    cellClassName: 'table-row',
    renderCell: DatagridListView,
    disableExport: true
  },
  {
    field: 'location',
    headerName: 'Location(s)',
    headerClassName: 'table-header',
    width: 220,
    cellClassName: 'table-row',
    renderCell: DatagridListView,
    disableExport: true
  },
  {
    field: 'interventions',
    headerName: 'Interventions',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.interventions ? row.interventions?.join('; ') : ''}`,
    renderCell: DatagridChipsPopup
  },
  {
    field: 'conditions',
    headerName: 'Conditions',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.conditions}`,
    renderCell: DatagridChipsPopup
  },
  {
    field: 'number_of_arms',
    headerName: 'Number of Arms',
    headerClassName: 'table-header',
    width: 150,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.number_of_arms}`,
    renderCell: DatagridTooltip
  },
  {
    field: 'design_groups',
    headerName: 'Arms',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.design_groups ? row.design_groups.split('(').join(' (') : ''}`,
    renderCell: DatagridChipsPopup
  },
  {
    field: 'comparator_arm',
    headerName: 'Comparator Arm',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.comparator_arm}`,
    renderCell: DatagridChipsPopup
  },
  {
    field: 'masking',
    headerName: 'Masking',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.masking}`,
    renderCell: DatagridTooltip
  },
  {
    field: 'subject_masked_flag',
    headerName: 'Masking Type',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    renderCell: DatagridAvatars,
    disableExport: true
  },
  {
    field: 'subject_masked',
    headerClassName: 'table-header',
    headerName: `Subject Masked`,
    width: 100,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.subject_masked || ' '} `
  },
  {
    field: 'investigator_masked',
    headerClassName: 'table-header',
    headerName: `Investigator Masked`,
    width: 100,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.investigator_masked || ' '} `
  },
  {
    field: 'caregiver_masked',
    headerClassName: 'table-header',
    headerName: `Caregiver Masked`,
    width: 100,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.caregiver_masked || ' '} `
  },
  {
    field: 'outcomes_assessor_masked',
    headerClassName: 'table-header',
    headerName: `Outcome Assessor Masked (${prefixText})`,
    width: 100,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.outcomes_assessor_masked || ' '} `
  },
  {
    field: 'has_expanded_access_flag',
    headerName: 'Other',
    headerClassName: 'table-header',
    width: 100,
    cellClassName: 'table-row',
    renderCell: DatagridAvatars,
    disableExport: true
  },
  {
    field: 'healthy_volunteers',
    headerClassName: 'table-header',
    headerName: `Healthy Volunteers`,
    width: 100,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.healthy_volunteers || ' '} `
  },
  {
    field: 'has_expanded_access',
    headerClassName: 'table-header',
    headerName: `Expanded Access`,
    width: 100,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.has_expanded_access || ' '} `
  },
  {
    field: 'allocation',
    headerName: 'Allocation',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.allocation}`,
    renderCell: DatagridTooltip
  },
  {
    field: 'first_submitted_year',
    headerName: 'First Submitted Year',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    renderCell: DatagridTooltip
  },
  {
    field: 'gender',
    headerName: 'Gender',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.gender}`,
    renderCell: DatagridTooltip
  },
  {
    field: 'last_updated_year',
    headerName: 'Last Updated Year',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    renderCell: DatagridTooltip
  },
  {
    field: 'official_title',
    headerName: 'Official Title',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.official_title}`,
    renderCell: DatagridTooltip
  },
  {
    field: 'overall_status',
    headerName: 'Overall Status',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.overall_status}`,
    renderCell: DatagridTooltip
  },
  {
    field: 'primary_completion_date',
    headerName: 'Primary Completion Date',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    renderCell: DatagridTooltip
  },
  {
    field: 'sponsors',
    headerName: 'Sponsors',
    headerClassName: 'table-header',
    width: 360,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.sponsors ? row.sponsors?.join('; ') : ''}`,
    renderCell: DatagridChipsPopup
  },
  {
    field: 'study_completion',
    headerName: 'Study Completion Date',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    renderCell: DatagridTooltip
  },
  // primary_outcome_csv and secondary_outcome_csv will not be display on ui but will be display on csv
  {
    field: 'primary_outcome_csv',
    headerName: 'Primary Outcome',
    headerClassName: 'table-header',
    width: 220,
    cellClassName: 'table-row',
    valueGetter: ({ row }) =>
      `${
        row.primary_outcome && row.primary_outcome.length !== 0
          ? getFormatDataForCSV(row.primary_outcome)
          : ''
      }`
  },
  {
    field: 'secondary_outcome_csv',
    headerName: 'Secondary Outcome',
    headerClassName: 'table-header',
    width: 220,
    cellClassName: 'table-row',
    valueGetter: ({ row }) =>
      `${
        row.secondary_outcome && row.secondary_outcome.length !== 0
          ? getFormatDataForCSV(row.secondary_outcome)
          : ''
      }`
  },
  {
    field: 'location_csv',
    headerName: 'Location(s)',
    headerClassName: 'table-header',
    width: 220,
    renderCell: DatagridTooltip,
    cellClassName: 'table-row',
    valueGetter: ({ row }) =>
      `${row.location && row.location.length !== 0 ? getFormatDataForCSV(row.location) : ''}`
  }
];

export const EU_CT_COLUMNS: GridColDef[] = [
  {
    field: 'Origin URL',
    headerName: '',
    headerClassName: 'table-header',
    width: 50,
    cellClassName: 'table-row',
    disableExport: true,
    valueGetter: ({ row }) => `${row.euct_id}_`,
    renderCell: DatagridActionsMenu,
    // @ts-ignore
    source: 'euctr'
  },
  {
    field: 'euct_id',
    headerName: 'EUCT ID',
    headerClassName: 'table-header',
    width: 140,
    cellClassName: 'table-row',
    renderCell: DatagridTooltip,
    // @ts-ignore
    source: 'ct'
  },
  {
    field: 'ct_link',
    headerName: 'Source Link',
    headerClassName: 'table-header',
    width: 140,
    cellClassName: 'table-row',
    filterable: false,
    // @ts-ignore
    isLink: true,
    valueGetter: ({ row }) =>
      `https://www.clinicaltrialsregister.eu/ctr-search/search?query=${row.euct_id}`,
    // @ts-ignore
    source: 'ct'
  },
  {
    field: 'official_title',
    headerName: 'Study Title',
    headerClassName: 'table-header',
    width: 280,
    cellClassName: 'table-row',
    renderCell: renderCellExpand,
    valueGetter: ({ row }) => `${row.official_title}`
  },
  {
    field: 'phase',
    headerName: 'Phase',
    headerClassName: 'table-header',
    width: 200,
    cellClassName: 'table-row',
    valueGetter: ({ row }) =>
      row.phase_formatted
        .map((each: any) =>
          each.phase ? `${each.phase}:${each.member_state.split(', ').join(';')}` : ''
        )
        .filter((str: string) => str !== '')
        .join(','),
    renderCell: DatagridNestedListView,
    sortable: false
  },
  {
    field: 'age_category',
    headerName: 'Age Group',
    headerClassName: 'table-header',
    width: 250,
    cellClassName: 'table-row',
    renderCell: DatagridListViewWithAvatars,
    valueGetter: ({ row }) =>
      row.protocols
        .map((each: any) => `${each.member_state}:${each.age_category.join(';')}`)
        .join(','),
    // @ts-ignore
    source: 'euctr',
    sortable: false
  },
  {
    field: 'enrollment_info',
    headerName: 'Number Enrolled',
    headerClassName: 'table-header',
    width: 150,
    cellClassName: 'table-row',
    renderCell: DatagridNestedListView,
    valueGetter: ({ row }) =>
      row.enrollment_info_formatted
        .map((each: any) =>
          each.enrollment_info
            ? `${each.enrollment_info}:${each.member_state.split(', ').join(';')}`
            : ''
        )
        .filter((str: string) => str !== '')
        .join(','),
    sortable: false
  },
  {
    field: 'primary_outcome',
    headerName: 'Primary Outcome',
    headerClassName: 'table-header',
    width: 220,
    cellClassName: 'table-row',
    renderCell: DatagridListView,
    sortable: false,
    disableExport: true
  },
  {
    field: 'secondary_outcome',
    headerName: 'Secondary Outcome',
    headerClassName: 'table-header',
    width: 220,
    cellClassName: 'table-row',
    renderCell: DatagridListView,
    sortable: false,
    disableExport: true
  },
  {
    field: 'primary_outcome_csv',
    headerName: 'Primary Outcome',
    headerClassName: 'table-header',
    width: 220,
    cellClassName: 'table-row',
    valueGetter: ({ row }) =>
      `${
        row.primary_outcome && row.primary_outcome.length !== 0
          ? getFormatDataForCSV(row.primary_outcome)
          : ''
      }`
  },
  {
    field: 'secondary_outcome_csv',
    headerName: 'Secondary Outcome',
    headerClassName: 'table-header',
    width: 220,
    cellClassName: 'table-row',
    valueGetter: ({ row }) =>
      `${
        row.secondary_outcome && row.secondary_outcome.length !== 0
          ? getFormatDataForCSV(row.secondary_outcome)
          : ''
      }`
  },
  {
    field: 'interventions',
    headerName: 'Interventions',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) =>
      row.interventions_formatted
        .map((each: any) =>
          isTruthyValue(each.interventions)
            ? `${each.interventions}:${each.member_state.split(', ').join(';')}`
            : ''
        )
        .filter((str: any) => str !== '')
        .join(','),
    renderCell: DatagridNestedListView
  },
  {
    field: 'conditions',
    headerName: 'Conditions',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) =>
      row.conditions_formatted
        .map((each: any) =>
          isTruthyValue(each.conditions)
            ? `${each.conditions}:${each.member_state.split(', ').join(';')}`
            : ''
        )
        .filter((str: any) => str !== '')
        .join(','),
    renderCell: DatagridNestedListView
  },
  {
    field: 'number_of_arms',
    headerName: 'Number of Arms',
    headerClassName: 'table-header',
    width: 150,
    cellClassName: 'table-row',
    valueGetter: ({ row }) =>
      row.number_of_arms_formatted
        .map((each: any) =>
          each.number_of_arms
            ? `${each.number_of_arms}:${each.member_state.split(', ').join(';')}`
            : ''
        )
        .filter((str: any) => str !== '')
        .join(','),
    renderCell: DatagridNestedListView,
    sortable: false
  },
  {
    field: 'design_groups',
    headerName: 'Arms',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) =>
      row.design_groups_formatted
        .map((each: any) => `${each.design_groups}:${each.member_state.split(', ').join(';')}`)
        .join(','),
    renderCell: DatagridNestedListView
  },
  {
    field: 'comparator_arm',
    headerName: 'Comparator Arm',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) =>
      row.comparator_arm_formatted
        .map((each: any) =>
          isTruthyValue(each.comparator_arm)
            ? `${each.comparator_arm}:${each.member_state.split(', ').join(';')}`
            : ''
        )
        .filter((str: string) => str !== '')
        .join(','),
    renderCell: DatagridNestedListView
  },
  {
    field: 'masking',
    headerName: 'Masking',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) =>
      row.masking_formatted
        .map((each: any) =>
          each.masking ? `${each.masking}:${each.member_state.split(',').join(';')}` : ''
        )
        .filter((str: any) => str !== '')
        .join(','),
    renderCell: DatagridNestedListView
  },
  {
    field: 'healthy_volunteers',
    headerClassName: 'table-header',
    headerName: `Healthy Volunteers`,
    width: 100,
    cellClassName: 'table-row',
    valueGetter: ({ row }) =>
      row.healthy_volunteers_formatted
        .map((each: any) =>
          isTruthyValue(each.healthy_volunteers)
            ? `${each.healthy_volunteers ? 'Yes' : 'No'}:${each.member_state.split(', ').join(';')}`
            : ''
        )
        .filter((str: any) => str !== '')
        .join(','),
    renderCell: DatagridNestedListView
  },
  {
    field: 'allocation',
    headerName: 'Allocation',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) =>
      row.allocation_formatted
        .map((each: any) =>
          each.allocation ? `${each.allocation}:${each.member_state.split(', ').join(';')}` : ''
        )
        .filter((str: any) => str !== '')
        .join(','),
    renderCell: DatagridNestedListView
  },
  {
    field: 'results_first_posted_date',
    headerName: 'First Submitted Year',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    renderCell: DatagridTooltip
  },
  {
    field: 'gender',
    headerName: 'Gender',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    renderCell: DatagridTooltip
  },
  {
    field: 'overall_status',
    headerName: 'Overall Status',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) =>
      row.overall_status_formatted
        .map((each: any) =>
          isTruthyValue(each.overall_status)
            ? `${each.overall_status}:${each.member_state.split(', ').join(';')}`
            : ''
        )
        .filter((str: any) => str !== '')
        .join(','),
    renderCell: DatagridNestedListView
  },
  {
    field: 'primary_completion_date',
    headerName: 'Primary Completion Date',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    renderCell: DatagridTooltip
  },
  {
    field: 'sponsors',
    headerName: 'Sponsors',
    headerClassName: 'table-header',
    width: 360,
    cellClassName: 'table-row',
    valueGetter: ({ row }) =>
      row.sponsors_formatted
        .map((each: any) =>
          isTruthyValue(each.sponsors)
            ? `${each.sponsors}:${each.member_state.split(', ').join(';')}`
            : ''
        )
        .filter((str: any) => str !== '')
        .join(','),
    renderCell: DatagridNestedListView
  },
  {
    field: 'study_completion',
    headerName: 'Study Completion Date',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    renderCell: DatagridTooltip
  }
];

export const CT_ADVERSE_EVENTS: GridColDef[] = [
  {
    field: 'nct_id2',
    headerName: 'Link',
    headerClassName: 'table-header',
    width: 50,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `https://clinicaltrials.gov/ct2/show/results/${row.nct_id2}`,
    renderCell: DatagridLink,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'nct_id',
    headerName: 'NCT ID',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    renderCell: DatagridColorNumber,
    // @ts-ignore
    source: 'ct'
  },
  {
    field: 'result_group_title',
    headerName: 'Result Group Title',
    headerClassName: 'table-header',
    width: 300,
    cellClassName: 'table-row',
    renderCell: DatagridTooltip,
    valueGetter: ({ row }) => `${row.result_group_title}`
  },
  {
    field: 'result_group_description',
    headerName: 'Result Group Description',
    headerClassName: 'table-header',
    width: 400,
    cellClassName: 'table-row',
    renderCell: renderCellExpand,
    valueGetter: ({ row }) => `${row.result_group_description}`
  },
  {
    field: 'event_type',
    headerName: 'Event Type',
    headerClassName: 'table-header',
    width: 200,
    cellClassName: 'table-row',
    renderCell: DatagridTooltip,
    valueGetter: ({ row }) => `${row.event_type}`,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'organ_system',
    headerName: 'Organ System',
    headerClassName: 'table-header',
    width: 400,
    cellClassName: 'table-row',
    renderCell: DatagridTooltip,
    valueGetter: ({ row }) => `${row.organ_system}`
  },
  {
    field: 'adverse_event_term',
    headerName: 'Adverse Event Term',
    headerClassName: 'table-header',
    width: 350,
    cellClassName: 'table-row',
    renderCell: DatagridTooltip,
    valueGetter: ({ row }) => `${row.adverse_event_term}`
  },
  {
    field: 'subjects_affected',
    headerName: 'N Affected',
    headerClassName: 'table-header',
    width: 200,
    cellClassName: 'table-row',
    renderCell: DatagridTooltip,
    valueGetter: ({ row }) => `${row.subjects_affected}`,
    align: 'center',
    headerAlign: 'center',
    type: 'number'
  },
  {
    field: 'subjects_at_risk',
    headerName: 'N at Risk',
    headerClassName: 'table-header',
    width: 200,
    cellClassName: 'table-row',
    renderCell: DatagridTooltip,
    valueGetter: ({ row }) => `${row.subjects_at_risk}`,
    align: 'center',
    headerAlign: 'center',
    type: 'number'
  },
  {
    field: 'risk_ratio',
    headerName: 'Incidence (%)',
    headerClassName: 'table-header',
    width: 200,
    cellClassName: 'table-row',
    renderCell: DatagridTooltip,
    valueGetter: ({ row }) => `${row.risk_ratio}`,
    align: 'center',
    headerAlign: 'center',
    type: 'number'
  }
];

export const EU_CT_ADVERSE_EVENTS: GridColDef[] = [
  {
    field: 'euct_id2',
    headerName: 'Link',
    headerClassName: 'table-header',
    width: 150,
    cellClassName: 'table-row',
    valueGetter: ({ row }) =>
      `https://www.clinicaltrialsregister.eu/ctr-search/trial/${row.euct_id}/results`,
    renderCell: (params: any) => DatagridLink(params, 'euct'),
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'euct_id',
    headerName: 'EUCT ID',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    renderCell: DatagridColorNumber,
    // @ts-ignore
    source: 'ct'
  },
  {
    field: 'result_group_title',
    headerName: 'Result Group Title',
    headerClassName: 'table-header',
    width: 300,
    cellClassName: 'table-row',
    renderCell: DatagridTooltip,
    valueGetter: ({ row }) => `${row.result_group_title}`
  },
  {
    field: 'result_group_description',
    headerName: 'Result Group Description',
    headerClassName: 'table-header',
    width: 400,
    cellClassName: 'table-row',
    renderCell: renderCellExpand,
    valueGetter: ({ row }) => `${row.result_group_description}`
  },
  {
    field: 'event_type',
    headerName: 'Event Type',
    headerClassName: 'table-header',
    width: 200,
    cellClassName: 'table-row',
    renderCell: DatagridTooltip,
    valueGetter: ({ row }) => `${row.event_type}`,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'organ_system',
    headerName: 'Organ System',
    headerClassName: 'table-header',
    width: 400,
    cellClassName: 'table-row',
    renderCell: DatagridTooltip,
    valueGetter: ({ row }) => `${row.organ_system}`
  },
  {
    field: 'adverse_event_term',
    headerName: 'Adverse Event Term',
    headerClassName: 'table-header',
    width: 350,
    cellClassName: 'table-row',
    renderCell: DatagridTooltip,
    valueGetter: ({ row }) => `${row.adverse_event_term}`
  },
  {
    field: 'subjects_affected',
    headerName: 'N Affected',
    headerClassName: 'table-header',
    width: 200,
    cellClassName: 'table-row',
    renderCell: DatagridTooltip,
    valueGetter: ({ row }) => `${row.subjects_affected}`,
    align: 'center',
    headerAlign: 'center',
    type: 'number'
  },
  {
    field: 'subjects_at_risk',
    headerName: 'N at Risk',
    headerClassName: 'table-header',
    width: 200,
    cellClassName: 'table-row',
    renderCell: DatagridTooltip,
    valueGetter: ({ row }) => `${row.subjects_at_risk}`,
    align: 'center',
    headerAlign: 'center',
    type: 'number'
  },
  {
    field: 'risk_ratio',
    headerName: 'Incidence (%)',
    headerClassName: 'table-header',
    width: 200,
    cellClassName: 'table-row',
    renderCell: DatagridTooltip,
    valueGetter: ({ row }) => `${row.risk_ratio}`,
    align: 'center',
    headerAlign: 'center',
    type: 'number'
  }
];

export const CANADA_COLUMNS: GridColDef[] = [
  {
    field: 'identifier',
    headerName: 'Application Number',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    renderCell: DatagridIdetifier
  },
  {
    field: 'source',
    headerName: 'Source',
    headerClassName: 'table-header',
    width: 100,
    cellClassName: 'table-row',
    renderCell: DatagridSourceFlag
  },
  {
    field: 'labels',
    headerName: 'Application Type',
    headerClassName: 'table-header',
    width: 140,
    cellClassName: 'table-row',
    renderCell: DatagridApplicationType
  },
  {
    field: 'product_name',
    headerName: 'Product Name',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => titleCase(`${row.product_name}`),
    renderCell: DatagridProductNameReg360
  },
  {
    field: 'generic_name',
    headerName: 'Generic Name',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.generic_name}`,
    renderCell: DatagridTooltip
  },
  {
    field: 'sponsor_name',
    headerClassName: 'table-header',
    headerName: 'Sponsor Name',
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.sponsor_name}`,
    renderCell: DatagridTooltip
  },
  {
    field: 'approval_year',
    headerClassName: 'table-header',
    headerName: 'First Approval Year ',
    width: 180,
    cellClassName: 'table-row'
  },
  {
    field: 'indications_and_usage',
    headerClassName: 'table-header',
    headerName: 'Indications ',
    width: 240,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.indications_and_usage}`,
    renderCell: renderCellExpand
  },
  {
    field: 'formulation',
    headerClassName: 'table-header',
    headerName: 'Formulation',
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) =>
      `${Array.isArray(row.formulation) ? row.formulation?.join('; ') : row.formulation || ''}`,
    renderCell: DatagridChipsPopup
  },
  {
    field: 'route',
    headerClassName: 'table-header',
    headerName: 'Route',
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) =>
      `${Array.isArray(row.route) ? row.route?.join('; ') : row.route || ''}`,
    renderCell: DatagridChipsPopup
  },
  {
    field: 'atc_classification',
    headerClassName: 'table-header',
    headerName: 'ATC Class',
    width: 180,
    cellClassName: 'table-row',
    renderCell: ATCDataGridPopUpChip,
    disableExport: true,
    filterOperators: getATCFilterOperator()
  },
  {
    field: 'approvalTracks',
    headerClassName: 'table-header',
    headerName: 'Approval Tracks',
    width: 240,
    cellClassName: 'table-row',
    renderCell: DatagridApprovalTracks,
    disableExport: true
  },
  {
    field: 'noc_w_condition_flag',
    headerClassName: 'table-header',
    headerName: `NOC with Condition (${prefixText})`,
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.noc_w_condition_text || 'No'} `
  },
  {
    field: 'priority_review_flag',
    headerClassName: 'table-header',
    headerName: `Priority Review (${prefixText})`,
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.priority_review_text || 'No'} `
  }
];

export const AUSTRALIA_COLUMNS: GridColDef[] = [
  {
    field: 'vin',
    headerName: 'Application Number',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    renderCell: DatagridIdetifier
  },
  {
    field: 'source',
    headerName: 'Source',
    headerClassName: 'table-header',
    width: 100,
    cellClassName: 'table-row',
    renderCell: DatagridSourceFlag
  },
  {
    field: 'product_name',
    headerName: 'Product Name',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => titleCase(`${row.product_name}`),
    renderCell: DatagridProductNameReg360
  },
  {
    field: 'generic_name',
    headerName: 'Generic Name',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.generic_name}`,
    renderCell: DatagridTooltip
  },
  {
    field: 'sponsor_name',
    headerClassName: 'table-header',
    headerName: 'Sponsor Name',
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.sponsor_name}`,
    renderCell: DatagridTooltip
  },
  {
    field: 'approval_year',
    headerClassName: 'table-header',
    headerName: 'First Approval Year ',
    width: 180,
    cellClassName: 'table-row'
  },
  {
    field: 'indications_and_usage',
    headerClassName: 'table-header',
    headerName: 'Indications ',
    width: 240,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.indications_and_usage}`,
    renderCell: renderCellExpand
  },
  {
    field: 'formulation',
    headerClassName: 'table-header',
    headerName: 'Formulation',
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) =>
      `${Array.isArray(row.formulation) ? row.formulation?.join('; ') : row.formulation || ''}`,
    renderCell: DatagridChipsPopup
  },
  {
    field: 'route',
    headerClassName: 'table-header',
    headerName: 'Route',
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) =>
      `${Array.isArray(row.route) ? row.route?.join('; ') : row.route || ''}`,
    renderCell: DatagridChipsPopup
  }
];

export const HPRA_COLUMNS: GridColDef[] = [
  {
    field: 'identifier',
    headerName: 'Product Number',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    renderCell: DatagridIdetifier
  },
  {
    field: 'source',
    headerName: 'Source',
    headerClassName: 'table-header',
    width: 100,
    cellClassName: 'table-row',
    renderCell: DatagridSourceFlag
  },
  {
    field: 'labels',
    headerName: 'Application Type',
    headerClassName: 'table-header',
    width: 140,
    cellClassName: 'table-row',
    renderCell: DatagridApplicationType
  },
  {
    field: 'product_name',
    headerName: 'Product Name',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => titleCase(`${row.product_name}`),
    renderCell: DatagridProductNameReg360
  },
  {
    field: 'generic_name',
    headerName: 'Generic Name',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    renderCell: DatagridTooltip
  },
  {
    field: 'sponsor_name',
    headerClassName: 'table-header',
    headerName: 'Sponsor Name',
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.sponsor_name}`,
    renderCell: DatagridTooltip
  },
  {
    field: 'approval_year',
    headerClassName: 'table-header',
    headerName: 'First Approval Year ',
    width: 180,
    cellClassName: 'table-row'
  },
  {
    field: 'indications_and_usage',
    headerClassName: 'table-header',
    headerName: 'Indications ',
    width: 240,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.indications_and_usage}`,
    renderCell: renderCellExpand
  },
  {
    field: 'dosage',
    headerClassName: 'table-header',
    headerName: 'Formulation',
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.formulation}`,
    renderCell: DatagridTooltip
  },
  {
    field: 'route',
    headerClassName: 'table-header',
    headerName: 'Route',
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.route}`,
    renderCell: DatagridTooltip
  },
  {
    field: 'atc_classification',
    headerClassName: 'table-header',
    headerName: 'ATC Class',
    width: 180,
    cellClassName: 'table-row',
    renderCell: ATCDataGridPopUpChip,
    disableExport: true,
    filterOperators: getATCFilterOperator()
  },
  {
    field: 'atc_classification_export',
    headerClassName: 'table-header',
    filterable: false,
    headerName: 'ATC Class',
    width: 0,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => valueMapper(row.atc_classification, true)
  },
  {
    field: 'approvalTracks',
    headerClassName: 'table-header',
    headerName: 'Approval Status',
    width: 180,
    cellClassName: 'table-row',
    renderCell: DatagridApprovalTracks,
    disableExport: true
  },
  {
    field: 'marketing_text',
    headerClassName: 'table-header',
    headerName: `Marketing Status (${prefixText})`,
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.marketing_text || 'No'} `,
    renderCell: DatagridTooltip
  },
  {
    field: 'supply_status',
    headerClassName: 'table-header',
    headerName: `Supply Status (${prefixText})`,
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.supply_status || 'No'} `,
    renderCell: DatagridTooltip
  },
  {
    field: 'advertising_status',
    headerClassName: 'table-header',
    headerName: `Advertising Status (${prefixText})`,
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.advertising_status || 'No'} `,
    renderCell: DatagridTooltip
  },
  {
    field: 'legal_status',
    headerClassName: 'table-header',
    headerName: `Legal Status (${prefixText})`,
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.legal_status || 'No'} `,
    renderCell: DatagridTooltip
  }
];

export const HMA_COLUMNS: GridColDef[] = [
  {
    field: 'identifier',
    headerName: 'Product Number',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    renderCell: DatagridIdetifier
  },
  {
    field: 'source',
    headerName: 'Source',
    headerClassName: 'table-header',
    width: 100,
    cellClassName: 'table-row',
    renderCell: DatagridSourceFlag
  },
  {
    field: 'labels',
    headerName: 'Application Type',
    headerClassName: 'table-header',
    width: 140,
    cellClassName: 'table-row',
    renderCell: DatagridApplicationType
  },
  {
    field: 'product_name',
    headerName: 'Product Name',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.product_name}`,
    renderCell: DatagridProductNameReg360
  },
  {
    field: 'generic_name',
    headerName: 'Generic Name',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.generic_name}`,
    renderCell: DatagridTooltip
  },
  {
    field: 'sponsor_name',
    headerClassName: 'table-header',
    headerName: 'Sponsor Name',
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.sponsor_name}`,
    renderCell: DatagridTooltip
  },
  {
    field: 'approval_year',
    headerClassName: 'table-header',
    headerName: 'First Approval Year ',
    width: 180,
    cellClassName: 'table-row'
  },
  {
    field: 'indications_and_usage',
    headerClassName: 'table-header',
    headerName: 'Indications ',
    width: 240,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.indications_and_usage}`,
    renderCell: renderCellExpand
  },
  {
    field: 'formulation',
    headerClassName: 'table-header',
    headerName: 'Formulation',
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.formulation}`,
    renderCell: DatagridTooltip
  },
  {
    field: 'atc_classification',
    headerClassName: 'table-header',
    headerName: 'ATC Class',
    width: 180,
    cellClassName: 'table-row',
    renderCell: ATCDataGridPopUpChip,
    disableExport: true,
    filterOperators: getATCFilterOperator()
  },
  {
    field: 'atc_classification_export',
    headerClassName: 'table-header',
    filterable: false,
    headerName: 'ATC Class',
    width: 0,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => valueMapper(row.atc_classification, true)
  },
  {
    field: 'marketing_text',
    headerClassName: 'table-header',
    headerName: `Marketing Status (${prefixText})`,
    width: 180,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.marketing_text || 'No'} `,
    renderCell: DatagridTooltip
  }
];
