/* eslint-disable no-unused-vars */
/* eslint-disable react/require-default-props */
import React, { useContext, useEffect } from 'react';

// MUI
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
// Interfaces
import TimelineColumnInterface from '../../interfaces/TimelineColumnInterface';
import styles from './CustomTimeline.styles';
import { TIMELINE_HEADERS, TIMELINE_INFO } from './constants';
import CsvDownload from '../csvdownload/CsvDownload';
import { fileNomenclature } from '../csvdownload/csvPropsType';
import CustomTimeLineSkeleton from './TimelineSkeleton/TimelineSkeleton';

import Actions from '../../store/actions';
import Store from '../../store';
import RegulatoryInformationStore from '../../store/RegulatoryInformation';
import RegulatoryActions from '../../store/RegulatoryInformation/actions';
import { getApplicationType } from '../../pages/RegulatoryInformation/ApprovalHistory/utils';
import getFormatDataForCSV from '../../pages/ResultsPage/utils/getFormatDataForCSV';
import getCSVData, { DEVICES_FLAG } from '../../pages/ResultsPage/utils/getCSVdata';

interface CustomTimelineProps {
  columns: TimelineColumnInterface[];
  timeline: any[];
  triggeredBy: string;
  filename: fileNomenclature;
  showFilters?: boolean;
  filtersApplied?: boolean;
  isLoading?: boolean;
  showOptions?: boolean;
  source: string;
}

const CustomTimeline = ({
  columns,
  timeline = [],
  triggeredBy,
  filename,
  showFilters = false,
  filtersApplied = false,
  isLoading = false,
  showOptions = true,
  source
}: CustomTimelineProps) => {
  const { state, dispatch } = useContext(Store) as any;
  const { regulatoryState, regulatoryDispatch } = useContext(RegulatoryInformationStore) as any;

  const addToReport = async () => {
    const applicationType = getApplicationType(
      source as string,
      regulatoryState?.applicationDetails
    );
    await dispatch({
      type: Actions.SET_CART_ITEMS,
      value: state.cartItems + 1
    });
    await dispatch({
      type: Actions.SET_REPORT,
      value: { id: 'Approval History', data: { columns, timeline, triggeredBy, applicationType } }
    });
  };

  useEffect(() => {
    if (regulatoryState.triggerAddtoReport) {
      addToReport();
      regulatoryDispatch({ type: RegulatoryActions.SET_TRIGGER_ADDTOREPORT, value: false });
    }
  }, [regulatoryState.triggerAddtoReport]);

  useEffect(() => {
    if (regulatoryState.triggerCsvDownload) {
      document.getElementById('csvDownloadBtn')?.click();
      regulatoryDispatch({ type: RegulatoryActions.SET_TRIGGER_CSVDOWNLOAD, value: false });
    }
  }, [regulatoryState.triggerCsvDownload]);

  const createcomponent = (column: TimelineColumnInterface, dataItem: any) => {
    const MyComponent = column.rendercomponent;
    return React.cloneElement(MyComponent, { column, dataItem, triggeredBy, source });
  };

  if (isLoading) {
    return <CustomTimeLineSkeleton />;
  }

  const getCSVValues = (data: any) => {
    const csvdata = JSON.parse(JSON.stringify(data));
    csvdata.forEach((row: any) => {
      const rapporteur: Array<string> = [];
      const coRapporteur: Array<string> = [];
      Object.entries(row).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          if (value.length > 0 && typeof value[0] === 'object') {
            if (key === 'rapporteur') {
              value.forEach((ele: any) => {
                if (ele.designation === 'rapporteur') {
                  rapporteur.push(ele);
                } else {
                  coRapporteur.push(ele);
                }
              });
            } else {
              // eslint-disable-next-line no-param-reassign
              row[key] = value;
            }
          }
        }
        if (DEVICES_FLAG.includes(key)) {
          if (value) {
            // eslint-disable-next-line no-param-reassign
            row[key] = 'Yes';
          } else {
            // eslint-disable-next-line no-param-reassign
            row[key] = 'No';
          }
        }
      });
      // eslint-disable-next-line no-param-reassign
      row.rapporteur = getFormatDataForCSV(rapporteur);
      // eslint-disable-next-line no-param-reassign
      row.co_rapporteur = getFormatDataForCSV(coRapporteur);
    });
    const domain = new URL(window.location.href).origin;
    return getCSVData(source, csvdata, domain);
  };

  return (
    <Box>
      {timeline?.length > 0 && (
        <Box>
          {showOptions && source in TIMELINE_HEADERS && (
            <Stack sx={{ display: 'none' }}>
              <CsvDownload
                id='csvDownloadBtn'
                data={getCSVValues(timeline)}
                filedata={filename}
                headers={
                  TIMELINE_HEADERS[
                    getApplicationType(source as string, regulatoryState?.applicationDetails)
                  ][triggeredBy]
                }
                className={styles.csvLink}>
                <Tooltip title='CSV downloa sdsd'>
                  <FileDownloadOutlinedIcon fontSize='small' sx={{ color: 'gray.dark' }} />
                </Tooltip>
              </CsvDownload>
            </Stack>
          )}
          <Box>
            <Timeline sx={styles.timeline}>
              <Grid container sx={{ paddingLeft: '30px', paddingBottom: '5px' }}>
                {columns.map(col => {
                  return (
                    <Grid
                      item
                      xs={col.width}
                      key={col.data}
                      sx={{
                        textAlign: 'center'
                      }}>
                      <Tooltip title={col?.tooltip as string}>
                        <Typography sx={styles.header}> {col.header} </Typography>
                      </Tooltip>
                    </Grid>
                  );
                })}
              </Grid>
              {timeline.map((itm: any, index) => {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <TimelineItem sx={(styles.timelineItem, styles.overrides)} key={index}>
                    <TimelineSeparator>
                      <TimelineDot
                        sx={
                          triggeredBy !== 'rems' &&
                          itm[
                            TIMELINE_INFO[
                              getApplicationType(
                                source as string,
                                regulatoryState?.applicationDetails
                              )
                            ][triggeredBy].dotField
                          ] ===
                            TIMELINE_INFO[
                              getApplicationType(
                                source as string,
                                regulatoryState?.applicationDetails
                              )
                            ][triggeredBy].dotFieldCondition
                            ? styles.timelineDotDarkGreen
                            : styles.timelineDotGreen
                        }>
                        <Tooltip
                          title={
                            itm[
                              TIMELINE_INFO[
                                getApplicationType(
                                  source as string,
                                  regulatoryState?.applicationDetails
                                )
                              ][triggeredBy].dotLabel
                            ]
                          }>
                          <Typography variant='body1' noWrap sx={styles.dotlabel}>
                            {
                              itm[
                                TIMELINE_INFO[
                                  getApplicationType(
                                    source as string,
                                    regulatoryState?.applicationDetails
                                  )
                                ][triggeredBy].dotLabel
                              ]
                            }
                          </Typography>
                        </Tooltip>
                      </TimelineDot>
                      {index !== timeline.length - 1 && <TimelineConnector />}
                    </TimelineSeparator>
                    <TimelineOppositeContent
                      style={{ maxWidth: '1px', paddingLeft: '0px', paddingRight: '0px' }}
                    />
                    <TimelineContent sx={styles.timelineTab}>
                      <Grid
                        container
                        sx={{
                          bgcolor: 'white.main',
                          marginBottom: '20px'
                        }}>
                        {columns.map((col: any) => {
                          return (
                            <Grid item xs={col?.width} key={col.name}>
                              {createcomponent(col, itm)}
                            </Grid>
                          );
                        })}
                      </Grid>
                    </TimelineContent>
                  </TimelineItem>
                );
              })}
            </Timeline>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default React.memo(CustomTimeline);
